import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchHeaderMenu } from '@services/common/headerMenu';
import { loadingStatus } from '@utils/loadingStatus';
import { IHeaderMenuState, IFetchMenuOptions } from '@dfTypes/header/headerMenu';
import { getTidyMenuData } from '@commonFeatures/header/transform/getTidyMenuData';
import { addCallApiMenuList } from '@commonFeatures/common/commonHeaderSlice';

export enum HeaderMenuState {
  Name = 'headerMenu',
}

const initialState: IHeaderMenuState = {
  loading: loadingStatus.idle,
  menu: [],
};
const FETCH_MENU: string = 'fetchMenu';

// current Menu
export const fetchMenu = createAsyncThunk(
  FETCH_MENU,
  async (options: IFetchMenuOptions, thunkAPI) => {
    const { signId } = options;
    try {
      const response = await fetchHeaderMenu({
        signId: signId === 'books' ? 'book' : signId,
        callback: 'cb_ecshopCategoryRegion',
      });
      const sortMenu = response
        ?.filter((item) => item.Nodes.length !== 0)
        ?.sort((a, b) => b.Sort - a.Sort); // 相關分類->品牌分類
      const tidyMenuData = getTidyMenuData(sortMenu);
      const tempMenuList = { [signId]: tidyMenuData };
      thunkAPI.dispatch(addCallApiMenuList(tempMenuList));
      return { response: tidyMenuData };
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const headerMenuSlice = createSlice({
  name: HeaderMenuState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMenu.pending, (state) => {
        state.loading = loadingStatus.pending;
        state.menu = [];
      })
      .addCase(fetchMenu.fulfilled, (state, action) => {
        state.loading = loadingStatus.succeeded;
        state.menu = action.payload.response;
      })
      .addCase(fetchMenu.rejected, (state) => {
        state.loading = loadingStatus.failed;
        state.menu = [];
      });
  },
});

export default headerMenuSlice.reducer;
