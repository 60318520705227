import axios from 'axios';
import '@utils/http-interceptor';
import { ecwebUrl } from '../utils/domain-route';

type Options = {
  sid: string | null;
  ts: string | null;
};

export function fetchConfig({ sid, ts }: Options) {
  let url = ecwebUrl({ path: '/cdn/switch/v1/config' });
  const isPreviewMode = sid && ts;
  if (isPreviewMode) url += `?op=preview&ts=${ts}&sid=${sid}`;

  return axios.get(url).then((response) => ({
    ...response,
  }));
}
