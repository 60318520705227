// https://ecapi.pchome.com.tw/member/v2/member/islogin?_callback=jsonpcb_MemberIsLogin
// https://ecapi.pchome.com.tw/member/v2/member?_callback=jsonpcb_MemberIsLogin
// http://ecapi1.dev.mypchome.com.tw/member/v2/member/islogin?_callback=jsonpcb_MemberIsLogin&ver=dev&login=1
// http://ecapi1.dev.mypchome.com.tw/member/v2/member?_callback=jsnop_memberinfo&ver=dev

// https://ecapi.pchome.com.tw/member/v2/member/id?_callback=jsonpcb_memberid&_=1700635367250

import { now } from '@utils/initTime';
import Fetch from '../utils/jsonp';
import { ecapiUrl } from '../utils/domain-route';

interface OptionsType {
  searchText: string;
  callback: string;
  memberX?: string;
}

function fetchLoginApi(options: OptionsType): Promise<any> {
  const { searchText = '', callback = '', memberX } = options;

  let url = '';
  const memberXString = memberX ? `&_=${memberX}` : '';
  if (searchText === 'islogin') {
    url = ecapiUrl({
      path: '/member/v2/member/islogin',
      isNormal: true,
    });
  } else {
    //  member 加入 X
    url = ecapiUrl({
      path: `/member/v2/member?_callback=${callback}${memberXString}`,
      isNormal: true,
    });
  }

  return Fetch.jsonp({
    url,
    callback,
  })
    .then((body) => body)
    .catch((error) => {
      return error;
    });
}

function fetchUserAccount(): Promise<string> {
  const callback = 'jsonpcb_memberid';
  const timestamp = now.toString();
  const url = ecapiUrl({ path: `/member/v2/member/id?_callback=${callback}&_=${timestamp}` });

  return Fetch.jsonp({
    url,
    callback,
  });
}

export { fetchLoginApi, fetchUserAccount };
