// https://ecssl-cart.pchome.com.tw/cart/index.php/prod/modify?callback=jsonp_modshopcart&1653893685597

import { ItemCartParamProps, PersonalItemCartResponse } from '@dfTypes/personalItemCart/Response';
import { now } from '@utils/initTime';
import { isOnline } from '@app/utils/config/env';
import Fetch from '../utils/jsonp';
import { ecsslUrl } from '../utils/domain-route';

interface OptionsType {
  paramData: ItemCartParamProps;
  callback?: string;
}

async function fetchPersonalItemCartCount(options: OptionsType): Promise<PersonalItemCartResponse> {
  const { paramData = { TB: '24H' }, callback = 'jsonp_modshopcart' } = options;
  const timestamp = now?.toString();
  const data = JSON.stringify(paramData);

  let url = ecsslUrl({
    path: `/cart/index.php/prod/modify?data=${data}&callback=${callback}&${timestamp}`,
    isEcsslCart: true,
  });

  if (!isOnline) {
    url = ecsslUrl({
      path: `/cart/stage/index.php/prod/modify?data=${data}&callback=${callback}&${timestamp}`,
      isEcsslCart: true,
    });
  }

  return Fetch.jsonp({
    url,
    data,
    callback: `${callback}`,
  });
}

export { fetchPersonalItemCartCount };
