import jsonP from 'jsonp';

export default class Fetch {
  static jsonp(options) {
    const controller = new AbortController();

    return new Promise((resolve, reject) => {
      jsonP(
        options.url,
        {
          param: options?.param || '_callback',
          name: options?.callback,
          signal: controller.signal,
        },
        (err, response) => {
          if (response) {
            resolve(response);
          } else {
            reject(err);
            // Cancel fetch in progress
            controller.abort();
          }
        }
      );
    });
  }
}
