// /config/env.ts
export enum ReactEnvironment {
  Dev = 'dev',
  Stage = 'stage',
  Online = 'online',
}

export enum ReactDomain {
  Eip = 'eip',
  Web = 'web',
}

export enum NodeEnvironment {
  Production = 'production',
}

export const publicUrl = process.env.PUBLIC_URL;
export const reactAppEnv = process.env.REACT_APP_ENV;
export const reactAppDomain = process.env.REACT_APP_DOMAIN;
export const nodeEnv = process.env.NODE_ENV;

export const isDev: boolean = reactAppEnv === ReactEnvironment.Dev;

export const isStage: boolean = reactAppEnv === ReactEnvironment.Stage;

export const isOnline: boolean = reactAppEnv === ReactEnvironment.Online;

export const isDomainEip: boolean = reactAppDomain === ReactDomain.Eip;

export const isDomainWeb: boolean = reactAppDomain === ReactDomain.Web;

export const isNodeProduction: boolean = nodeEnv === NodeEnvironment.Production;

export const reCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;

export const reCaptchaV2CheckboxKey = process.env.REACT_APP_RECAPTCHA_V2_CHECKBOX_SITE_KEY;
