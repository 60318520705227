// 一般 initial state
const initialDescData = {
  Approve: '',
  Author: '',
  Equip: '',
  Id: '',
  Kword: '',
  Liability: '',
  Meta: { Brand: '', BrandEng: '', MarcoMatchName: [], MarcoMatchInfo: [] },
  Pubdate: '',
  Pubunit: '',
  Remark: '',
  Slogan: '',
  Stmt: '',
  Transman: '',
  SloganInfo: [],
  RltProdInfo: [],
};
// 食品initial state
const initialFoodDescData = {
  Ingredient: '',
  NetWeightOrQuantity: '',
  NativeCounty: '',
  ShelfLife: '',
  VendorName: '',
  VendorTel: '',
  VendorAddr: '',
  InsureNo: '',
  RegisterNo: '',
  Memo: '',
  SafetyDays: null,
};

// 食品肉類清單
const meatSurface = {
  Chicken: '雞',
  Pork: '豬',
  Lamb: '羊',
  Duck: '鴨',
  Beef: '牛',
};

export { initialDescData, initialFoodDescData, meatSurface };
