import { isNotBookRegion } from '../utils';

// 篩選-非18禁商品排除推薦18禁商品、排除書店商品(DJ、DN)
export const toRecommendIdList = ({ isNC17, R18List, productList }) => {
  return (
    productList
      // eslint-disable-next-line array-callback-return
      .map((product: string) => {
        if (isNotBookRegion(product.slice(0, 2)) ?? false) {
          // R18
          if (isNC17) return `${product}-000`;

          // underage
          if (!isNC17 && !R18List.some((filterItem) => product.includes(filterItem))) {
            return `${product}-000`;
          }
        }
      })
      .filter(Boolean)
  );
};

export const toRecommendAlsoBuyList = ({ regionId, isNC17, R18List, regionList, productList }) => {
  // 主商品為寢具商品[DEAT:寢具枕, DEBC:名寢]
  const isBedding = regionId.includes('DEAT') || regionId.includes('DEBC');
  // 非18禁商品排除推薦18禁商品、排除書店商品(DJ、DN)
  const list = productList
    // eslint-disable-next-line array-callback-return
    .map((product: string) => {
      if (isNotBookRegion(product.slice(0, 2))) {
        // R18
        if (isNC17) return `${product}-000`;

        // underage
        if (!isNC17 && !R18List.some((filterItem) => product.includes(filterItem))) {
          return `${product}-000`;
        }
      }
    })
    .filter(Boolean);

  // 也買額外篩選-顯示當前區館列表商品
  const newProductList = list
    // eslint-disable-next-line array-callback-return
    .map((product: string) => {
      if (regionList.some((filterItem: string) => product.includes(filterItem))) return product;
    })
    .filter(Boolean);

  const newBeddingList = list
    // eslint-disable-next-line array-callback-return
    .map((product: string) => {
      if (isBedding && regionList.some((filterItem) => product.includes(filterItem))) {
        return product;
      }
    })
    .filter(Boolean);

  return isBedding ? newBeddingList : newProductList;
};

export const toRecommendOutfitIdList = ({ isNC17, R18List, productList }) => {
  return (
    productList
      // eslint-disable-next-line array-callback-return
      .map((product: { ProdId: string; StyleName: string }) => {
        if (isNotBookRegion(product.ProdId.slice(0, 2)) ?? false) {
          // R18
          if (isNC17) return `${product.ProdId}-000`;

          // underage
          if (!isNC17 && !R18List.some((filterItem) => product.ProdId.includes(filterItem))) {
            return `${product.ProdId}-000`;
          }
        }
      })
      .filter(Boolean)
  );
};
