// 加價購specList
export const toSpecList = ({ data }) => {
  return data
    .filter((filter) => filter.Qty > 0) // 庫存為0不顯示
    .map((item) => ({
      specSeq: item.Seq,
      specId: item.Id,
      specName: item.Spec,
      specQty: item?.Qty,
      isWarranty: item.isWarranty,
    }));
};
