// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/prod/DBAY11-A9007F0G9/foodcontents&_callback=jsonp_fooddesc?_callback=jsonp_fooddesc
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface OptionsType {
  prodId: string;
  version: string;
}

function fetchProdFoodDesc(options: OptionsType): Promise<any> {
  const { prodId, version } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/prodapi/${version}/prod/${prodId}/foodcontents&_callback=jsonp_fooddesc`,
  });

  return Fetch.jsonp({
    url,
    callback: 'jsonp_fooddesc',
  }).then((body) => body);
}
export { fetchProdFoodDesc };
