export const gaKeyData = {
  gold: [
    {
      key: 'b002_bn_a_01_01',
    },
    {
      key: 'b002_bn_a_01_02',
    },
  ],
  hero: [
    {
      key: 'b003_bn_a_01_01',
    },
    {
      key: 'b003_bn_a_01_02',
    },
    {
      key: 'b003_bn_a_01_03',
    },
    {
      key: 'b003_bn_a_01_04',
    },
    {
      key: 'b003_bn_a_01_05',
    },
    {
      key: 'b003_bn_a_01_06',
    },
    {
      key: 'b003_bn_a_01_07',
    },
    {
      key: 'b003_bn_a_01_08',
    },
    {
      key: 'b003_bn_a_01_09',
    },
    {
      key: 'b003_bn_a_01_10',
    },
    {
      key: 'b003_bn_a_01_11',
    },
    {
      key: 'b003_bn_a_01_12',
    },
    {
      key: 'b003_bn_a_01_13',
    },
    {
      key: 'b003_bn_a_01_14',
    },
    {
      key: 'b003_bn_a_01_15',
    },
  ],
  festival: [
    {
      key: 'b004_bn_a_01_01',
    },
    {
      key: 'b004_bn_a_01_02',
    },
    {
      key: 'b004_bn_a_01_03',
    },
  ],
  hit: [{ key: 'b006_bn_a_01_01' }, { key: 'b006_bn_a_01_02' }],
  brand: [
    { key: 'b008_lk_a_01_01' },
    { key: 'b008_lk_a_01_02' },
    { key: 'b008_lk_a_01_03' },
    { key: 'b008_lk_a_01_04' },
    { key: 'b008_lk_a_01_05' },
    { key: 'b008_lk_a_01_06' },
    { key: 'b008_lk_a_01_07' },
    { key: 'b008_lk_a_01_08' },
    { key: 'b008_lk_a_01_09' },
    { key: 'b008_lk_a_01_10' },
    { key: 'b008_lk_a_01_11' },
    { key: 'b008_lk_a_01_12' },
    { key: 'b008_lk_a_01_13' },
    { key: 'b008_lk_a_01_14' },
    { key: 'b008_lk_a_01_15' },
  ],
  hot: [
    { key: 'ub03_lk_a_01_01' },
    { key: 'ub03_lk_a_01_02' },
    { key: 'ub03_lk_a_01_03' },
    { key: 'ub03_lk_a_01_04' },
    { key: 'ub03_lk_a_01_05' },
    { key: 'ub03_lk_a_01_06' },
  ],
  window: {
    tag: [
      {
        key: 'b009_tg_b_01_01',
      },
      {
        key: 'b009_tg_b_01_02',
      },
      {
        key: 'b009_tg_b_01_03',
      },
      {
        key: 'b009_tg_b_01_04',
      },
      {
        key: 'b009_tg_b_01_05',
      },
    ],
    content: [
      {
        key: 'b009_lk_c_01_01',
      },
      {
        key: 'b009_lk_c_01_02',
      },
      {
        key: 'b009_lk_c_01_03',
      },
      {
        key: 'b009_lk_c_01_04',
      },
      {
        key: 'b009_lk_c_01_05',
      },
      {
        key: 'b009_lk_c_01_06',
      },
      {
        key: 'b009_lk_c_01_07',
      },
      {
        key: 'b009_lk_c_01_08',
      },
      {
        key: 'b009_lk_c_01_09',
      },
      {
        key: 'b009_lk_c_01_10',
      },
      {
        key: 'b009_lk_c_01_11',
      },
      {
        key: 'b009_lk_c_01_12',
      },
      {
        key: 'b009_lk_c_01_13',
      },
      {
        key: 'b009_lk_c_01_14',
      },
      {
        key: 'b009_lk_c_01_15',
      },
      {
        key: 'b009_lk_c_01_16',
      },
      {
        key: 'b009_lk_c_01_17',
      },
      {
        key: 'b009_lk_c_01_18',
      },
    ],
    tab: [
      {
        key: 'b009_bm_a_01_01',
      },
      {
        key: 'b009_bm_a_01_02',
      },
      {
        key: 'b009_bm_a_01_03',
      },
      {
        key: 'b009_bm_a_01_04',
      },
      {
        key: 'b009_bm_a_01_05',
      },
      {
        key: 'b009_bm_a_01_06',
      },
      {
        key: 'b009_bm_a_01_07',
      },
      {
        key: 'b009_bm_a_01_08',
      },
    ],
  },
  window1: {
    tag: [
      {
        key: 'b010_tg_b_01_01',
      },
      {
        key: 'b010_tg_b_01_02',
      },
      {
        key: 'b010_tg_b_01_03',
      },
      {
        key: 'b010_tg_b_01_04',
      },
      {
        key: 'b010_tg_b_01_05',
      },
    ],
    content: [
      {
        key: 'b010_lk_c_01_01',
      },
      {
        key: 'b010_lk_c_01_02',
      },
      {
        key: 'b010_lk_c_01_03',
      },
      {
        key: 'b010_lk_c_01_04',
      },
      {
        key: 'b010_lk_c_01_05',
      },
      {
        key: 'b010_lk_c_01_06',
      },
      {
        key: 'b010_lk_c_01_07',
      },
      {
        key: 'b010_lk_c_01_08',
      },
      {
        key: 'b010_lk_c_01_09',
      },
      {
        key: 'b010_lk_c_01_10',
      },
      {
        key: 'b010_lk_c_01_11',
      },
      {
        key: 'b010_lk_c_01_12',
      },
      {
        key: 'b010_lk_c_01_13',
      },
      {
        key: 'b010_lk_c_01_14',
      },
      {
        key: 'b010_lk_c_01_15',
      },
      {
        key: 'b010_lk_c_01_16',
      },
      {
        key: 'b010_lk_c_01_17',
      },
      {
        key: 'b010_lk_c_01_18',
      },
    ],
    tab: [
      {
        key: 'b010_bm_a_01_01',
      },
      {
        key: 'b010_bm_a_01_02',
      },
      {
        key: 'b010_bm_a_01_03',
      },
      {
        key: 'b010_bm_a_01_04',
      },
      {
        key: 'b010_bm_a_01_05',
      },
      {
        key: 'b010_bm_a_01_06',
      },
      {
        key: 'b010_bm_a_01_07',
      },
      {
        key: 'b010_bm_a_01_08',
      },
    ],
  },
  window2: {
    tag: [
      {
        key: 'b011_tg_b_01_01',
      },
      {
        key: 'b011_tg_b_01_02',
      },
      {
        key: 'b011_tg_b_01_03',
      },
      {
        key: 'b011_tg_b_01_04',
      },
      {
        key: 'b011_tg_b_01_05',
      },
    ],
    content: [
      {
        key: 'b011_lk_c_01_01',
      },
      {
        key: 'b011_lk_c_01_02',
      },
      {
        key: 'b011_lk_c_01_03',
      },
      {
        key: 'b011_lk_c_01_04',
      },
      {
        key: 'b011_lk_c_01_05',
      },
      {
        key: 'b011_lk_c_01_06',
      },
      {
        key: 'b011_lk_c_01_07',
      },
      {
        key: 'b011_lk_c_01_08',
      },
      {
        key: 'b011_lk_c_01_09',
      },
      {
        key: 'b011_lk_c_01_10',
      },
      {
        key: 'b011_lk_c_01_11',
      },
      {
        key: 'b011_lk_c_01_12',
      },
      {
        key: 'b011_lk_c_01_13',
      },
      {
        key: 'b011_lk_c_01_14',
      },
      {
        key: 'b011_lk_c_01_15',
      },
      {
        key: 'b011_lk_c_01_16',
      },
      {
        key: 'b011_lk_c_01_17',
      },
      {
        key: 'b011_lk_c_01_18',
      },
    ],
    tab: [
      {
        key: 'b011_bm_a_01_01',
      },
      {
        key: 'b011_bm_a_01_02',
      },
      {
        key: 'b011_bm_a_01_03',
      },
      {
        key: 'b011_bm_a_01_04',
      },
      {
        key: 'b011_bm_a_01_05',
      },
      {
        key: 'b011_bm_a_01_06',
      },
      {
        key: 'b011_bm_a_01_07',
      },
      {
        key: 'b011_bm_a_01_08',
      },
    ],
  },
  fullBanner: [
    {
      key: 'b012_bn_a_01_00',
    },
  ],
  fiveBanner: [
    {
      key: 'b013_bn_a_01_01',
    },
    {
      key: 'b013_bn_a_01_02',
    },
    {
      key: 'b013_bn_a_01_03',
    },
    {
      key: 'b013_bn_a_01_04',
    },
    {
      key: 'b013_bn_a_01_05',
    },
  ],
  coupon: [
    {
      key: 'b007_bn_b_01_01',
      link: 'b007_bt_b_02_01',
      linkBtn: 'b007_tl_a_01_00',
    },
    {
      key: 'b007_bn_b_01_02',
      link: 'b007_bt_b_02_02',
    },
    {
      key: 'b007_bn_b_01_03',
      link: 'b007_bt_b_02_03',
    },
    {
      key: 'b007_bn_b_01_04',
      link: 'b007_bt_b_02_04',
    },
    {
      key: 'b007_bn_b_01_05',
      link: 'b007_bt_b_02_05',
    },
    {
      key: 'b007_bn_b_01_06',
      link: 'b007_bt_b_02_06',
    },
    {
      key: 'b007_bn_b_01_07',
      link: 'b007_bt_b_02_07',
    },
    {
      key: 'b007_bn_b_01_08',
      link: 'b007_bt_b_02_08',
    },
    {
      key: 'b007_bn_b_01_09',
      link: 'b007_bt_b_02_09',
    },
    {
      key: 'b007_bn_b_01_10',
      link: 'b007_bt_b_02_10',
    },
    {
      key: 'b007_bn_b_01_11',
      link: 'b007_bt_b_02_11',
    },
    {
      key: 'b007_bn_b_01_12',
      link: 'b007_bt_b_02_12',
    },
    {
      key: 'b007_bn_b_01_13',
      link: 'b007_bt_b_02_13',
    },
    {
      key: 'b007_bn_b_01_14',
      link: 'b007_bt_b_02_14',
    },
    {
      key: 'b007_bn_b_01_15',
      link: 'b007_bt_b_02_15',
    },
  ],
  bank: [
    {
      key: 'b005_bn_b_01_01',
      linkBtn: 'b005_bt_a_01_00',
    },
    {
      key: 'b005_bn_b_01_02',
    },
    {
      key: 'b005_bn_b_01_03',
    },
    {
      key: 'b005_bn_b_01_04',
    },
    {
      key: 'b005_bn_b_01_05',
    },
    {
      key: 'b005_bn_b_01_06',
    },
    {
      key: 'b005_bn_b_01_07',
    },
    {
      key: 'b005_bn_b_01_08',
    },
    {
      key: 'b005_bn_b_01_09',
    },
    {
      key: 'b005_bn_b_01_10',
    },
    {
      key: 'b005_bn_b_01_11',
    },
    {
      key: 'b005_bn_b_01_12',
    },
    {
      key: 'b005_bn_b_01_13',
    },
    {
      key: 'b005_bn_b_01_14',
    },
    {
      key: 'b005_bn_b_01_15',
    },
  ],
  welcome: [
    {
      linkBtn: 'ub01_bt_a_01_00',
      key: 'ub01_lk_b_01_01',
    },
    {
      key: 'ub01_lk_b_01_02',
    },
    {
      key: 'ub01_lk_b_01_03',
    },
  ],
  onsale: [
    {
      key: 'ub02_lk_a_01_01',
      top: 'ub02_tg_b_01_01',
      middle: 'ub02_tg_b_01_02',
      down: 'ub02_tg_b_01_03',
      linkBtn: 'ub02_bt_c_01_00',
    },
    {
      key: 'ub02_lk_a_01_02',
    },
    {
      key: 'ub02_lk_a_01_03',
    },
    {
      key: 'ub02_lk_a_01_04',
    },
    {
      key: 'ub02_lk_a_01_05',
    },
  ],
  cutprice: [{ linkBtn: 'ub04_tl_a_01_00', key: 'ub04_lk_b_01_' }],
  replenish: [{ linkBtn: 'ub05_tl_a_01_00', key: 'ub05_lk_b_01_' }],
  venraas: [{ key: 'ub06_lk_a_01_' }],
  video: [{ linkBtn: 'b014_tl_a_01_00', key: 'b014_bn_b_01_' }],
};
