import { RootState } from 'src/store';
import { ecshwebUrl } from '@services/utils/domain-route';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchSuggestKeyword } from '@services/common/search.api';
import { now } from '@utils/initTime';
import { loadingStatus } from '@utils/loadingStatus';
import {
  IHeaderSearchResponse,
  IHeaderKeywordResponse,
  IHeaderSearchState,
} from '@dfTypes/header/headerSearch';

export enum SearchState {
  Name = 'headerKeyword',
}

const initialState: IHeaderSearchState = {
  loading: loadingStatus.idle,
  searchText: '',
  keywordData: [],
};

const FETCH_SUGGEST_KEYWORD_DATA = 'header/autocomplete';

type TSearchResult = {
  BU: string;
  name: string;
  rurl: string;
  src: string;
};

function getSearchRurl(value: TSearchResult) {
  let { rurl } = value;

  if (!rurl) {
    rurl = `${ecshwebUrl(`/search/v3.3/?q=${value?.name}`)}`;
  }

  // mercari
  if (value?.BU) {
    rurl += `&scope=${value?.BU}`;
  }

  return rurl;
}

export const searchSuggestKeywordAsync = createAsyncThunk<
  IHeaderSearchState | any,
  void,
  { state }
>(
  FETCH_SUGGEST_KEYWORD_DATA,
  async (_, thunkAPI) => {
    const { searchText } = selectSearchText(thunkAPI.getState());
    const response: IHeaderSearchResponse[] | never = await fetchSuggestKeyword({
      searchText,
      limit: 15,
      timestamp: now.toString(),
    }).then((body) =>
      body?.map((value: any) => ({
        keyword: value?.name,
        bu: value?.BU || null,
        logoSrc: value?.src || null,
        rurl: getSearchRurl(value),
        murl: `https://24h.pchome.com.tw/search/?q=${value?.name}`,
        isGeneral: !value?.rurl && !value?.BU,
      }))
    );

    // order: brand => general => other business (i.e. bibian)
    const arrKeywords: IHeaderKeywordResponse[] = [
      { title: 'brand', suggestions: [] },
      { title: 'general', suggestions: [] },
    ];

    // sort keywords by categories
    response?.forEach((objKeyword: IHeaderSearchResponse) => {
      if (objKeyword?.bu) {
        const businessName = objKeyword?.bu === 'mcr' ? '比比昂日本購物' : '';
        const bu = arrKeywords?.find((obj) => obj.title === businessName);

        if (!bu) {
          arrKeywords?.push({ title: businessName, suggestions: [objKeyword] });
        } else {
          bu?.suggestions?.push(objKeyword);
        }
      } else if (objKeyword.logoSrc) {
        const brand = arrKeywords?.find((obj) => obj.title === 'brand');
        brand?.suggestions?.push(objKeyword);
      } else {
        const general = arrKeywords?.find((obj) => obj.title === 'general');
        general?.suggestions?.push(objKeyword);
      }
    });

    return arrKeywords;
  },
  {
    condition: (_) => {
      return true;
    },
  }
);

export const searchSlice = createSlice({
  name: SearchState.Name,
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    resetHeaderKeyword: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchSuggestKeywordAsync.pending, (state) => {
        state.loading = loadingStatus.pending;
      })
      .addCase(searchSuggestKeywordAsync.fulfilled, (state, action) => {
        state.keywordData = action.payload;
        state.loading = loadingStatus.succeeded;
      })
      .addCase(searchSuggestKeywordAsync.rejected, (state) => {
        state.loading = loadingStatus.failed;
      });
  },
});

type SearchSliceReducer = {
  [SearchState.Name]: ReturnType<typeof searchSlice.reducer>;
};

export const { setSearchText, resetHeaderKeyword }: any = searchSlice.actions;
export const selectSearchText = (state: RootState<SearchSliceReducer>) => state.headerKeyword;
export default searchSlice.reducer;
