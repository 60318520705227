import { ICouponRegionState } from '@dfTypes/sideMenu';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCouponRegion } from '@services/sideMenu/couponRegion.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';

export enum CouponRegionState {
  Name = 'couponRegion',
}

const initialState: ICouponRegionState = {
  couponRegionLoading: loadingStatus.idle,
  couponRegionTotal: 0,
};

const FETCH_COUPON_REGION: string = 'sideMenu/fetchCouponRegion';

export const getCouponRegion = createAsyncThunk(
  FETCH_COUPON_REGION,
  async (regionId: string, thunkAPI) => {
    try {
      const response = await fetchCouponRegion({
        callback: 'jsonp_couponregion',
        version: 'v3',
        regionId,
      });

      return Number(response) || 0;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const couponRegionSlice = createSlice({
  name: CouponRegionState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCouponRegion.pending, (state) => {
        state.couponRegionLoading = loadingStatus.pending;
        state.couponRegionTotal = 0;
      })
      .addCase(getCouponRegion.fulfilled, (state, action) => {
        state.couponRegionLoading = loadingStatus.succeeded;
        state.couponRegionTotal = action.payload;
      })
      .addCase(getCouponRegion.rejected, (state) => {
        state.couponRegionLoading = loadingStatus.failed;
        state.couponRegionTotal = 0;
      });
  },
});

type CouponRegionSliceReducer = {
  [CouponRegionState.Name]: ReturnType<typeof couponRegionSlice.reducer>;
};

export const couponRegion = (state: RootState<CouponRegionSliceReducer>) => state.couponRegion;
export default couponRegionSlice.reducer;
