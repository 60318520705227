/* eslint-disable no-console */
/* eslint-disable no-return-await */
/* eslint-disable array-callback-return */
import { IRecommendState } from '@dfTypes/recommend';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';

import { getAllRecommendTask } from './utils/getAllRecommendTask';
import { getR18List } from './utils/getR18List';
import { recommendConfig } from './utils';

export enum RecommendState {
  Name = 'recommend',
}

const initialState: IRecommendState = {
  recommendLoading: loadingStatus.idle,
  recommendTitle: '',
  recommendKind: 0,
  recommend: {
    tabData: [],
    prodData: [],
  },
  errorMessage: '',
};
const FETCH_RECOMMEND = 'prod/fetchRecommend';
type TRecommendThunkProps = {
  prodId: string;
  regionId: string;
  signId: string;
  isNC17: boolean;
};

export const getRecommend = createAsyncThunk(
  FETCH_RECOMMEND,
  async (obj: TRecommendThunkProps, _thunkAPI) => {
    const { regionId } = obj;
    const isClothing: boolean = ['DICU', 'DICF', 'DIAI'].includes(regionId);
    const R18List: string[] = await getR18List();
    return Promise.all(await getAllRecommendTask({ ...obj, isClothing, R18List })).then(
      (response) => {
        if (!response) {
          throw new Error('recommend data error');
        }
        const tabData: any = [];
        const recommendProd = response
          .map((data) => {
            const hasData: boolean = data.product.length > 0;
            const recommendTabs = hasData
              ? {
                  id: data.id,
                  name: recommendConfig[Number(data.id) - 1].title,
                  gtmName: recommendConfig[Number(data.id) - 1].id,
                }
              : undefined;
            hasData && tabData.push(recommendTabs);
            return { ...data };
          })
          .filter((item) => item.product.length > 0);

        return {
          recommend: { tabData, prodData: recommendProd },
          recommendKind: tabData.length,
          recommendTitle: tabData.length === 1 ? tabData[0].name : '其他推薦',
          errorMessage: '',
        };
      }
    );
  }
);

const recommendSlice = createSlice({
  name: RecommendState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRecommend.pending, (state) => {
        state.recommendLoading = loadingStatus.pending;
        state.recommendTitle = '';
        state.recommendKind = 0;
        state.recommend = { tabData: [], prodData: [] };
        state.errorMessage = '';
      })
      .addCase(getRecommend.fulfilled, (state, action) => {
        state.recommendLoading = loadingStatus.succeeded;
        state.recommendTitle = action.payload.recommendTitle;
        state.recommendKind = action.payload.recommendKind;
        state.recommend = action.payload.recommend;
        state.errorMessage = action.payload.errorMessage;
      })
      .addCase(getRecommend.rejected, (state) => {
        state.recommendLoading = loadingStatus.failed;
        state.recommendTitle = '';
        state.recommendKind = 0;
        state.recommend = { tabData: [], prodData: [] };
        state.errorMessage = 'rejected';
      });
  },
});

type RecommendSliceReducer = {
  [RecommendState.Name]: ReturnType<typeof recommendSlice.reducer>;
};

export const recommendData = (state: RootState<RecommendSliceReducer>) => state.recommend;
export default recommendSlice.reducer;
