import { INewArrivalRegionState } from '@dfTypes/sideMenu';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchNewArrivalRegion } from '@services/sideMenu/newArrivalRegion.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';

export enum NewArrivalRegionState {
  Name = 'newArrivalRegion',
}

const initialState: INewArrivalRegionState = {
  newArrivalRegionLoading: loadingStatus.idle,
  newArrivalRegionTotal: 0,
};

const FETCH_NEW_ARRIVAL_REGION: string = 'sideMenu/fetchNewArrivalRegion';

export const getNewArrivalRegion = createAsyncThunk(
  FETCH_NEW_ARRIVAL_REGION,
  async (regionId: string, thunkAPI) => {
    try {
      const response = await fetchNewArrivalRegion({
        callback: 'jsonp_newarrivalregion',
        version: 'v2',
        regionId,
      });
      return Number(response) || 0;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const newArrivalRegionSlice = createSlice({
  name: NewArrivalRegionState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNewArrivalRegion.pending, (state) => {
        state.newArrivalRegionLoading = loadingStatus.pending;
        state.newArrivalRegionTotal = 0;
      })
      .addCase(getNewArrivalRegion.fulfilled, (state, action) => {
        state.newArrivalRegionLoading = loadingStatus.succeeded;
        state.newArrivalRegionTotal = action.payload;
      })
      .addCase(getNewArrivalRegion.rejected, (state) => {
        state.newArrivalRegionLoading = loadingStatus.failed;
        state.newArrivalRegionTotal = 0;
      });
  },
});

type NewArrivalRegionSliceReducer = {
  [NewArrivalRegionState.Name]: ReturnType<typeof newArrivalRegionSlice.reducer>;
};

export const newArrivalRegion = (state: RootState<NewArrivalRegionSliceReducer>) =>
  state.newArrivalRegion;
export default newArrivalRegionSlice.reducer;
