import { AddOnProd } from '@dfTypes/prodAdd';
import { toSpecList } from '@commonFeatures/additional/transform/toSpecList.transformer';
import { getFullImgDomain } from '@utils/getFullImgDomain';

export const toAddOnProductCardData = ({ data, prodAddList, addOnProdDetail }): AddOnProd[] => {
  return prodAddList.map((productId, idx) => {
    const specList = toSpecList({ data: data[productId] });
    const newData = {
      Id: productId,
      Name: data[productId]?.[0].Name,
      Group: data[productId]?.[0].Group,
      salePrice: data[productId]?.[0].Price.M,
      price: data[productId]?.[0].Price.P,
      imageAlt: data[productId]?.[0].Name,
      imageSrc: getFullImgDomain(data[productId]?.[0].Pic.B),
      imageSrcS: getFullImgDomain(data[productId]?.[0].Pic.S),
      specList,
      // gtm code
      gtmItemId: productId.slice(0, 16),
      gtmItemName: data[productId]?.[0].Name,
      gtmCurrency: 'NTD',
      gtmItemBrand: undefined,
      gtmItemCategory: undefined, // 區4碼
      gtmItemCategory2: undefined, // 館6碼
      gtmItemListId: 'addon',
      gtmPrice: data[productId]?.[0].Price.P,
      gtmQuantity: 1,
      gtmIndex: idx + 1,
      isCombine: addOnProdDetail?.[`${productId}-000`]?.isCombine ?? 0,
      isDescAndIntroSync: addOnProdDetail?.[`${productId}-000`]?.isDescAndIntroSync ?? 0,
      isFoodContents: addOnProdDetail?.[`${productId}-000`]?.isFoodContents ?? 0,
      isMedical: addOnProdDetail?.[`${productId}-000`]?.isMedical ?? 0,
    };
    return newData;
  });
};
