import {
  TActivityStoreInformationResponse,
  TActivityStoreState,
} from '@dfTypes/common/activityStore';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchActivityStoreInformation from '@services/common/activityStore.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';

export enum ActivityStoreState {
  Name = 'activityStore',
}

const initialState: TActivityStoreState = {
  activityStoreLoading: loadingStatus.idle,
  activityStoreInformation: {
    ActId: '',
    ActName: '',
    StartDate: '',
    EndDate: '',
    Description: { Detail: [], Short: '', Full: '', Min: '' },
    Url: '',
    Region: '',
  },
};
// 用活動ID查詢活動館資訊
export const getActivityStoreInformation = createAsyncThunk(
  'get/fetchActivityStoreInformation',
  async (actId: string, thunkAPI) => {
    try {
      const response: TActivityStoreInformationResponse = await fetchActivityStoreInformation({
        actId,
      });
      return response?.[0];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const activityStoreSlice = createSlice({
  name: ActivityStoreState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getActivityStoreInformation.pending, (state) => {
        state.activityStoreLoading = loadingStatus.pending;
      })
      .addCase(getActivityStoreInformation.fulfilled, (state, action) => {
        state.activityStoreLoading = loadingStatus.succeeded;
        state.activityStoreInformation = action.payload;
      })
      .addCase(getActivityStoreInformation.rejected, (state) => {
        state.activityStoreLoading = loadingStatus.failed;
      });
  },
});

type activityStoreSliceReducer = {
  [ActivityStoreState.Name]: ReturnType<typeof activityStoreSlice.reducer>;
};

export const activityStore = (state: RootState<activityStoreSliceReducer>) => state?.activityStore;
export default activityStoreSlice.reducer;
