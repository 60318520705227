import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchMemberTrackProdList } from '@services/common/prodTrackButton.api';
import { loadingStatus } from '@utils/loadingStatus';
import { IProdMemberTrackState } from '@dfTypes/prodTrackButton';
import { RootState } from 'src/store';

export enum ProdMemberTrackListState {
  Name = 'prodMemberTrackList',
}

const initialState: IProdMemberTrackState = {
  prodMemberTrackLoading: loadingStatus.idle,
  memberProdTrackList: [],
  errorMessage: null,
};

// 查詢追蹤商品資料
export const getMemberProdTrackList = createAsyncThunk(
  'get/prodMemberTrackList',
  async (
    {
      memberId,
      trackTime,
      prefix,
    }: { memberId: string; trackTime: number | string; prefix?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await fetchMemberTrackProdList({
        memberId,
        timestamp: trackTime?.toString(),
        prefix: prefix || '',
      });
      return response?.Rows;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const prodMemberTrackListSlice = createSlice({
  name: ProdMemberTrackListState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberProdTrackList.pending, (state) => {
        state.prodMemberTrackLoading = loadingStatus.pending;
      })
      .addCase(getMemberProdTrackList.fulfilled, (state, action) => {
        state.prodMemberTrackLoading = loadingStatus.succeeded;
        state.memberProdTrackList = action.payload;
      })
      .addCase(getMemberProdTrackList.rejected, (state, action) => {
        state.prodMemberTrackLoading = loadingStatus.failed;
        state.errorMessage = action.payload;
      });
  },
});

type ProdMemberTrackListSliceReducer = {
  [ProdMemberTrackListState.Name]: ReturnType<typeof prodMemberTrackListSlice.reducer>;
};

export const prodMemberTrackList = (state: RootState<ProdMemberTrackListSliceReducer>) =>
  state?.prodMemberTrackList;
export default prodMemberTrackListSlice.reducer;
