import { AddOnProd } from '@dfTypes/prodAdd';
import { getFullImgDomain } from '@utils/getFullImgDomain';

export const toMainProductCardData = ({ data, specData, limitQty }): AddOnProd[] => {
  const { Id, Name, Price, Pic, Qty, isWarranty, isSpec, isCombine } = data;
  const sigleSpec = [
    {
      specSeq: data.Seq,
      specId: Id,
      specName: Name,
      specQty: isCombine === 1 ? limitQty : Qty,
      isWarranty,
    },
  ];
  const mutilSpec = specData
    .filter((filter) => filter.Qty > 0) // 庫存為0不顯示
    ?.map((item) => ({
      specSeq: item.Seq,
      specId: item.Id,
      specName: item.Spec,
      specQty: item.Qty,
      isWarranty: item.isWarranty,
    }));
  const specList = () => {
    if (isSpec === 1) {
      return mutilSpec;
    }
    return sigleSpec;
  };
  return [
    {
      Id,
      Name,
      Group: isSpec === 1 ? Id : '',
      salePrice: Price.M,
      price: Price.P,
      primePrice: Price.Prime,
      lowPrice: Price.Low,
      imageAlt: Name,
      imageSrc: getFullImgDomain(Pic.B),
      imageSrcS: getFullImgDomain(Pic.S),
      specList: specList(),
    },
  ];
};
