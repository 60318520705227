import { getFullImgDomain } from '@utils/getFullImgDomain';
// dfTypes
import { IRecommendProdData } from '@dfTypes/recommend';
// services
import { ecwebUrl } from '@services/utils/domain-route';

export const toRecommendCard = ({ id, data, title, gtmItemList }) => {
  const prodData: IRecommendProdData[] = data.map((item, idx) => {
    const { Id, Name, Pic, Price } = item;
    return {
      id: Id,
      title: Name,
      imageSrcS: getFullImgDomain(Pic?.S),
      imageSrc: getFullImgDomain(Pic?.B),
      imgAlt: Name,
      link: ecwebUrl({ path: `/prod/${Id}`, isPageUrl: true }),
      salePrice: Price.M === 0 ? undefined : Price.M,
      price: Price.P,
      primePrice: Price.Prime,
      lowPrice: Price.Low,
      // gtm code
      gtmItemId: Id.slice(0, 16),
      gtmItemName: Name,
      gtmCurrency: 'NTD',
      gtmItemBrand: undefined,
      gtmItemCategory: undefined, // 區4碼
      gtmItemCategory2: undefined, // 館6碼
      gtmItemListId: gtmItemList,
      gtmPrice: Price.P,
      gtmQuantity: 1,
      gtmIndex: idx + 1,
    };
  });
  return { id, title, product: prodData };
};

export const toRecommendOutfitCard = ({ id, data, title, styleList, gtmItemList }) => {
  const prodData: IRecommendProdData[] = data.map((item, idx) => {
    const { Id, Name, Pic, Price } = item;
    const styleName: string =
      styleList
        .filter((filterItem) => filterItem.ProdId.slice(0, 16) === Id.slice(0, 16))
        .map((element) => element.StyleName)[0] ?? '';
    return {
      id: Id,
      title: Name,
      imageSrcS: getFullImgDomain(Pic?.S),
      imageSrc: getFullImgDomain(Pic?.B),
      imgAlt: Name,
      link: ecwebUrl({ path: `/prod/${Id}`, isPageUrl: true }),
      salePrice: Price.M === 0 ? undefined : Price.M,
      price: Price.P,
      primePrice: Price.Prime,
      lowPrice: Price.Low,
      styleName,
      // gtm code
      gtmItemId: Id.slice(0, 16),
      gtmItemName: Name,
      gtmCurrency: 'NTD',
      gtmItemBrand: undefined,
      gtmItemCategory: undefined, // 區4碼
      gtmItemCategory2: undefined, // 館6碼
      gtmItemListId: gtmItemList,
      gtmPrice: Price.P,
      gtmQuantity: 1,
      gtmIndex: idx + 1,
    };
  });
  return { id, title, product: prodData };
};
