import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchVideos } from '@services/common/video';
import { gaKeyData } from '@featuresHomepage/layout/utils/homepageGA';
import { now } from '@utils/initTime';
import { getFullImgDomain } from '@utils/getFullImgDomain';
import { loadingStatus } from '@utils/loadingStatus';
import { gaKeyGenerate } from '@utils/gaKeyGenerate';
import { RootState } from 'src/store';
import { IMediaVideoResponse, IMediaVideoState } from '@dfTypes/mediaVideo';

export enum VideoState {
  Name = 'homepageVideo',
}

const initialState: IMediaVideoState = {
  loading: loadingStatus.idle,
  videoData: [],
  errorMessage: '',
};

const FETCH_VIDEO = 'layout/video';

export const videoAsync = createAsyncThunk(FETCH_VIDEO, async (_, { rejectWithValue }) => {
  try {
    const response: IMediaVideoResponse[] = await fetchVideos({
      timestamp: now.toString(),
      callback: 'jsonp_video',
    });

    const GAKey = gaKeyGenerate(response?.length, gaKeyData?.video);

    return response?.map((objVideo, index) => ({
      id: objVideo?.Id,
      title: objVideo?.Name,
      link: objVideo?.VideoURL,
      imageAlt: objVideo?.Name,
      imageSrc: getFullImgDomain(objVideo?.Pic),
      liveEndTime: objVideo?.LiveEndTime,
      liveStartTime: objVideo?.LiveStartTime,
      startTime: objVideo?.StartTime,
      isLive: !!objVideo?.isLive,
      GA: GAKey[index],
      // gtm code
      gtmLocationId: 'video_banner',
      gtmPromotionId: objVideo?.Id, // 活動編號
      gtmPromotionName: objVideo?.Name, // 活動名稱
      gtmCreativeName: objVideo?.Name, // 圖片名稱(非必填可能為undefined)
      gtmCreativeSlot: `slot${index + 1}`, // 從1開始排序
    }));
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const videoSlice = createSlice({
  name: VideoState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(videoAsync.pending, (state) => {
        state.loading = loadingStatus.pending;
      })
      .addCase(videoAsync.fulfilled, (state, action) => {
        state.loading = loadingStatus.succeeded;
        state.videoData = action.payload;
      })
      .addCase(videoAsync.rejected, (state, action) => {
        state.loading = loadingStatus.failed;
        state.errorMessage = action.payload;
      });
  },
});

type VideoSliceReducer = {
  [VideoState.Name]: ReturnType<typeof videoSlice.reducer>;
};

export const selectVideo = (state: RootState<VideoSliceReducer>) => state.homepageVideo;
export default videoSlice.reducer;
