import { ecwebUrl } from '@services/utils/domain-route';
import { ToRelatedProdInfo } from '@dfTypes/prod/prodDesc/View';

const toRelatedProdInfo = ({ rltData, priceData }): ToRelatedProdInfo[] => {
  return rltData.map((item, idx) => {
    const prodButtonPrice = priceData.find((element) => element.Id.slice(0, 16) === item.Id)?.Price;
    const price = prodButtonPrice?.Low ?? prodButtonPrice?.P;
    const gtmCodeData = {
      gtmItemId: item.Id,
      gtmItemName: item.Name,
      gtmItemCurrency: 'TWD',
      gtmIndex: `${idx + 1}`,
      gtmItemBrand: undefined,
      gtmItemCategory: item.Id.slice(0, 4),
      gtmItemCategory2: item.Id.slice(0, 6),
      gtmItemListId: 'related_prod',
      gtmPrice: price,
      gtmQuantity: '1',
    };
    return {
      ...item,
      link: ecwebUrl({ path: `/prod/${item.Id}`, isPageUrl: true }),
      price: price ?? null,
      ...gtmCodeData,
    };
  });
};
export { toRelatedProdInfo };
