import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchPersonalItemCartCount } from '@app/services/common/personalItemCar.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import {
  PersonalItemCartState,
  PersonalItemCartResponse,
} from '@dfTypes/personalItemCart/Response';

export enum PersonalItemCartCountState {
  Name = 'personalItemCartCount',
}

const initialState: PersonalItemCartState = {
  personalCarCountLoading: loadingStatus.idle,
  personalCartCount: 0,
};

export const getPersonalItemCartCount = createAsyncThunk(
  'get/personalItemCartCount',
  async (_, thunkAPI) => {
    try {
      const response: PersonalItemCartResponse = await fetchPersonalItemCartCount({
        paramData: { TB: '24H' },
        callback: 'jsonp_modshopcart',
      });

      return response.PRODCOUNT ?? 0;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const personalItemCartCountSlice = createSlice({
  name: PersonalItemCartCountState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPersonalItemCartCount.pending, (state) => {
        state.personalCarCountLoading = loadingStatus.pending;
      })
      .addCase(getPersonalItemCartCount.fulfilled, (state, action) => {
        state.personalCarCountLoading = loadingStatus.succeeded;
        state.personalCartCount = action.payload;
      })
      .addCase(getPersonalItemCartCount.rejected, (state) => {
        state.personalCarCountLoading = loadingStatus.failed;
      });
  },
});

type personalItemCartCountSliceReducer = {
  [PersonalItemCartCountState.Name]: ReturnType<typeof personalItemCartCountSlice.reducer>;
};

export const personalItemCartCount = (state: RootState<personalItemCartCountSliceReducer>) =>
  state?.personalItemCartCount;

export default personalItemCartCountSlice.reducer;
