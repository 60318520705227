import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export enum CommonSmartAppStatusState {
  Name = 'commonSmartAppStatus',
}

const initialState = {
  smartAppStatus: true,
};

const commonSmartAppStatusSlice = createSlice({
  name: CommonSmartAppStatusState.Name,
  initialState,
  reducers: {
    setSmartAppStatus(state, action) {
      state.smartAppStatus = action.payload;
    },
  },
});

type CommonSmartAppStatusSliceReducer = {
  [CommonSmartAppStatusState.Name]: ReturnType<typeof commonSmartAppStatusSlice.reducer>;
};

export const { setSmartAppStatus } = commonSmartAppStatusSlice.actions;
export const commonSmartAppStatus = (state: RootState<CommonSmartAppStatusSliceReducer>) =>
  state.commonSmartAppStatus;
export default commonSmartAppStatusSlice.reducer;
