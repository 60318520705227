import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCMSContentPreviewInformation } from '@services/common/cmsContent.api';
import { loadingStatus } from '@utils/loadingStatus';
import { IContentPreviewImageState, TContentData } from '@dfTypes/cmsContent';
import { RootState } from 'src/store';

export enum CmsContentPreviewState {
  Name = 'cmsContentPreview',
}

const initialState: IContentPreviewImageState = {
  contentPreviewImageInformationLoading: loadingStatus.idle,
  contentPreviewImageInformation: {},
  errorMessage: null,
};
// 查詢content preview廣告資訊
export const getCmsContentPreviewInformation = createAsyncThunk<
  IContentPreviewImageState | any,
  { previewType: string; previewId: string },
  { state }
>('get/cmsContentPreview', async ({ previewType, previewId }, { rejectWithValue }) => {
  try {
    const response: TContentData = await fetchCMSContentPreviewInformation({
      previewType,
      previewId,
    });

    return response;
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const cmsContentPreviewInformationSlice = createSlice({
  name: CmsContentPreviewState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCmsContentPreviewInformation.pending, (state) => {
        state.contentPreviewImageInformationLoading = loadingStatus.pending;
      })
      .addCase(getCmsContentPreviewInformation.fulfilled, (state, action) => {
        state.contentPreviewImageInformationLoading = loadingStatus.succeeded;
        state.contentPreviewImageInformation = action.payload;
      })
      .addCase(getCmsContentPreviewInformation.rejected, (state, action) => {
        state.contentPreviewImageInformationLoading = loadingStatus.failed;
        state.errorMessage = action.payload;
      });
  },
});

type CmsContentPreviewInformationSliceReducer = {
  [CmsContentPreviewState.Name]: ReturnType<typeof cmsContentPreviewInformationSlice.reducer>;
};

export const cmsContentPreview = (state: RootState<CmsContentPreviewInformationSliceReducer>) =>
  state?.cmsContentPreview;
export default cmsContentPreviewInformationSlice.reducer;
