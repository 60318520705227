// fetchProdIntro
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getFullImgDomain } from '@utils/getFullImgDomain';
import { fetchProdIntro } from '@services/prod/prodIntro.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { IntroState } from '@dfTypes/prodIntro';
import { renderVersion } from '@utils/renderVersion';

export enum ProdIntroState {
  Name = 'prodIntro',
}

const initialState: IntroState = {
  introLoading: loadingStatus.idle,
  intro: [],
  addProdIntro: [], // 好康加購單品資訊內容
};

const FETCH_PROD_INTRO: string = 'prod/fetchProdIntro';

const getTidyIntro = (data) => {
  if (!data) {
    return [];
  }
  const newData = data.map((item) => {
    return {
      ...item,
      Pic: getFullImgDomain(item?.Pic),
      Sort: Number(item.Sort),
    };
  });

  if (newData.length === 1 && newData?.[0]?.Intro === '' && newData?.[0]?.Pic === '') {
    return [];
  }
  return newData;
};

export const getProdIntro = createAsyncThunk<
  IntroState | any,
  { prodId: string; sid?: string; isAddProd?: boolean },
  { state }
>(FETCH_PROD_INTRO, async ({ prodId, sid, isAddProd }) => {
  const introInformation = await fetchProdIntro({
    version: renderVersion({ sid, other: 'v2' }),
    prodId,
    token: sid,
  }).then((res) => {
    return getTidyIntro(res[prodId]);
  });

  return { introInformation, isAddProd };
});

const prodIntroSlice = createSlice({
  name: ProdIntroState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProdIntro.pending, (state) => {
        state.introLoading = loadingStatus.pending;
      })
      .addCase(getProdIntro.fulfilled, (state, action) => {
        state.introLoading = loadingStatus.succeeded;
        if (action.payload.isAddProd) {
          state.addProdIntro = action.payload.introInformation;
        } else {
          state.intro = action.payload.introInformation;
        }
      })
      .addCase(getProdIntro.rejected, (state) => {
        state.introLoading = loadingStatus.failed;
      });
  },
});

type ProdIntroSliceReducer = {
  [ProdIntroState.Name]: ReturnType<typeof prodIntroSlice.reducer>;
};

export const prodIntro = (state: RootState<ProdIntroSliceReducer>) => state.prodIntro;
export default prodIntroSlice.reducer;
