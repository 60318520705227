// https://ecapi.pchome.com.tw/ecshop/prodapi/v2/prod?id=${prodId}&fields=Id,Name,Pic,Price,Store&_callback=jsonp_prod_also&_callback=jsonp_prod_also
import { ecapiUrl } from '@app/services/utils/domain-route';
import Fetch from '../../utils/jsonp';

interface OptionsType {
  version: string;
  callback: string;
  prodId: string;
}

function fetchProdAlso(options: OptionsType): Promise<any> {
  const { version, callback, prodId } = options;
  const url = ecapiUrl({
    path: `/ecshop/prodapi/${version}/prod?id=${prodId}&fields=Id,Name,Pic,Price,Store&_callback=${callback}`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((res) => res);
}
export { fetchProdAlso };
