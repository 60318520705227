import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchConfig } from '@services/common/config';
import { loadingStatus } from '@utils/loadingStatus';
import { IConfigState } from '@dfTypes/config';
import { RootState } from 'src/store';

export enum ConfigDataState {
  Name = 'configData',
}

const initialState: IConfigState = {
  loading: loadingStatus.idle,
  indexMember: 0,
  indexShopCar: 0,
  logoSvgUrl: '',
  logoTitle: '',
  shouldConvert: 0,
  proIntroShouldConvert: 0,
  iphoneBlack: 0,
};

const FETCH_CONFIG = 'homepage/fetchConfig';
export const configAsync = createAsyncThunk<
  IConfigState | any,
  { sid: string | null; ts: string | null },
  { state }
>(
  FETCH_CONFIG,
  async ({ sid, ts }) => {
    const response = await fetchConfig({ sid, ts });

    return response;
  },
  {
    condition: (_, thunkAPI) => {
      const loading = selectLoading(thunkAPI.getState());
      const data: any = selectData(thunkAPI.getState());
      if ([loadingStatus.pending, loadingStatus.succeeded]?.includes(loading) || data.length) {
        return false;
      }
    },
  }
);

const configDataSlice = createSlice({
  name: ConfigDataState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(configAsync.pending, (state) => {
        state.loading = loadingStatus.pending;
      })
      .addCase(configAsync.fulfilled, (state, action) => {
        state.loading = loadingStatus.succeeded;
        state.indexMember = action.payload?.INDEX_MEMBER;
        state.indexShopCar = action.payload?.INDEX_SHOPCAR;
        state.logoSvgUrl = action.payload?.HEADER_LOGO_SVG ?? '';
        state.logoTitle = action.payload?.HEADER_LOGO_TITLE ?? '';
        state.shouldConvert = action.payload?.IS_DOMANIN_IMG ?? 0;
        state.proIntroShouldConvert = action.payload?.IS_DOMANIN_PROD_IMG ?? 0;
        state.iphoneBlack = action.payload?.IPHONE_BLACK ?? 0;
      })
      .addCase(configAsync.rejected, (state) => {
        state.loading = loadingStatus.failed;
      });
  },
});

type ConfigDataSliceReducer = {
  [ConfigDataState.Name]: ReturnType<typeof configDataSlice.reducer>;
};

export const selectLoading = (state: RootState<ConfigDataSliceReducer>) =>
  state?.configData?.loading ?? loadingStatus.idle;
export const selectData = (state: RootState<ConfigDataSliceReducer>) => state?.configData ?? {};
export default configDataSlice.reducer;
