// 取得是否顯示規格，備註
const getDescShow = (data) => {
  // 都沒有值時，不顯示
  if (!data?.Approve && !data?.Equip.trim() && !data?.Remark.trim() && !data?.Stmt.trim()) {
    return false;
  }

  // 只有Approve有值時，判斷是否為空值
  if (data?.Approve && !data?.Equip.trim() && !data?.Remark.trim() && !data?.Stmt.trim()) {
    const approveObj = JSON.parse(data.Approve);
    const { AP1, AP2, AP3, AP4, AP5, AP6, AP7, AP8, AP9, AP10, AP11 } = approveObj;
    if (!(AP1 || AP2 || AP3 || AP4 || AP5 || AP6 || AP7 || AP8 || AP9 || AP10 || AP11)) {
      return false;
    }
  }

  return true;
};

export { getDescShow };
