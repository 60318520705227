// 轉換有效期限顯示方式(天 -> 年月天)
const getShelfLife = (totalDay) => {
  let shelfLifeDay = `${totalDay}天`;
  const year = Math.floor(totalDay / 365);
  const month = (totalDay % 365) / 30;
  const day = (totalDay % 365) % 30;
  shelfLifeDay = `${year !== 0 ? `${year}年` : ''}${month !== 0 ? `${month}月` : ''}${
    day !== 0 ? `${day}天` : ''
  }`;
  return shelfLifeDay;
};
export { getShelfLife };
