// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/cateapi/v1.6/region/DEAW/menu&_callback=jsonp_nemu&5552435?_callback=jsonp_nemu
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface OptionsType {
  regionId: string;
}

function fetchRegionMenu(options: OptionsType): Promise<any> {
  const { regionId } = options;
  const url = ecapiUrl({
    path: `/ecshop/cateapi/v1.6/region/${regionId}/menu&_callback=jsonp_menu`,
  });

  return Fetch.jsonp({
    url,
    callback: 'jsonp_menu',
  }).then((res) => res);
}
export { fetchRegionMenu };
