import axios from 'axios';

axios.interceptors.request.use(
  (req) => req,
  (err) => Promise.reject(err)
);

axios.interceptors.response.use(
  (res) => {
    let response;
    if (res.status === 200) {
      response = res.data;
    }
    if (res.status === 201) {
      response = res;
    }
    if (res.status === 204) {
      response = res;
    }
    return response;
  },
  (error) => {
    if (!window.navigator.onLine) {
      // eslint-disable-next-line no-alert
      alert('請檢查網路連線');
      return;
    }
    return Promise.reject(error);
  }
);
