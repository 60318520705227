import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchNoticeSummary from '@services/common/fetchNoticeSummary.api';
import { loadingStatus } from '@utils/loadingStatus';
import { INoticeSummaryState } from '@dfTypes/noticeSummary';
import { RootState } from 'src/store';

export enum NoticeSummaryState {
  Name = 'noticeSummary',
}

const initialState: INoticeSummaryState = {
  noticeSummaryLoading: loadingStatus.idle,
  unreadCount: 0,
};

export const getNoticeSummary = createAsyncThunk(
  'get/fetchNoticeSummary',
  async (MBR: string, { rejectWithValue }) => {
    try {
      const response = await fetchNoticeSummary({
        MBR,
      });
      return response.map((el) => el.UnreadCount).reduce((a, b) => a + b);
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const noticeSummarySlice = createSlice({
  name: NoticeSummaryState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNoticeSummary.pending, (state) => {
        state.noticeSummaryLoading = loadingStatus.pending;
      })
      .addCase(getNoticeSummary.fulfilled, (state, action) => {
        state.noticeSummaryLoading = loadingStatus.succeeded;
        state.unreadCount = action.payload;
      })
      .addCase(getNoticeSummary.rejected, (state, action) => {
        state.noticeSummaryLoading = loadingStatus.failed;
        state.unreadCount = 0;
      });
  },
});

type NoticeSummarySliceReducer = {
  [NoticeSummaryState.Name]: ReturnType<typeof noticeSummarySlice.reducer>;
};

export const noticeSummary = (state: RootState<NoticeSummarySliceReducer>) => state?.noticeSummary;
export default noticeSummarySlice.reducer;
