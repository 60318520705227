import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCMSContentInformation } from '@services/common/cmsContent.api';
import { loadingStatus } from '@utils/loadingStatus';
import { IContentImageState, TContentData } from '@dfTypes/cmsContent';
import { RootState } from 'src/store';

export enum CmsContentState {
  Name = 'cmsContent',
}

const initialState: IContentImageState = {
  contentImageInformationLoading: loadingStatus.idle,
  contentImageInformation: {},
  errorMessage: null,
};
// 查詢content廣告資訊
export const getCmsContentInformation = createAsyncThunk<
  IContentImageState | any,
  { pageName: string; pageId?: string },
  { state }
>('get/cmsContent', async ({ pageName, pageId }, { rejectWithValue }) => {
  try {
    const response: TContentData = await fetchCMSContentInformation({
      pageName,
      pageId,
    });
    return { response };
  } catch (error: any) {
    return rejectWithValue(error.message);
  }
});

const cmsContentInformationSlice = createSlice({
  name: CmsContentState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCmsContentInformation.pending, (state) => {
        state.contentImageInformationLoading = loadingStatus.pending;
        state.contentImageInformation = {};
      })
      .addCase(getCmsContentInformation.fulfilled, (state, action) => {
        state.contentImageInformationLoading = loadingStatus.succeeded;
        state.contentImageInformation = action.payload?.response;
      })
      .addCase(getCmsContentInformation.rejected, (state, action) => {
        state.contentImageInformationLoading = loadingStatus.failed;
        state.errorMessage = action.payload;
        state.contentImageInformation = {};
      });
  },
});

type CmsContentInformationSliceReducer = {
  [CmsContentState.Name]: ReturnType<typeof cmsContentInformationSlice.reducer>;
};

export const cmsContent = (state: RootState<CmsContentInformationSliceReducer>) =>
  state?.cmsContent;
export default cmsContentInformationSlice.reducer;
