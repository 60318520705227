import { BestsellersMenu, IRegionMenuNode } from '@app/definitionTypes/sideMenu';

// 因為 top 30 為小標題, 但在 API Response 為大標題，所以將 top 30 放入小標題內（ Nodes )
// 並且把原本外面大標題的 Name 設為空字串，在 sidemenu 時判斷不顯示
export const transformToBestsellerSideMenu = (
  bestsellersMenuResponse: BestsellersMenu
): BestsellersMenu => {
  const menuNodes: IRegionMenuNode[] = [
    {
      ...bestsellersMenuResponse,
      Url: bestsellersMenuResponse.Path,
      isShow: true,
      title: bestsellersMenuResponse.Name ?? '',
    },
  ];
  return {
    ...bestsellersMenuResponse,
    Nodes: menuNodes,
    Name: '',
  };
};
