export const intLastModified1440M = parseInt(new Date().getTime() / 86400000, 10);
export const intLastModified60M = parseInt(new Date().getTime() / 3600000, 10);
export const intLastModified10M = parseInt(new Date().getTime() / 600000, 10);
export const intLastModified05M = parseInt(new Date().getTime() / 300000, 10);
export const intLastModified01M = parseInt(new Date().getTime() / 60000, 10);
export const intLastModified06s = new Date().getTime();
export const getDateTime = () => {
  return new Date().getTime();
};
export const randomNumber = (max, min) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};
export const now = Math.round(new Date().getTime() / 1000);

export const msNow = () => new Date().getTime();

export const payInfoTimestamp = Math.random()
  .toString(36)
  .replace(/[\d\*]/g, '')
  .substring(2, 8);
const pad = (v) => {
  return v < 10 ? `0${v}` : v;
};
// YYYYMMDDhhmmss
export const getDateString = (date) => {
  const year = date.getFullYear();
  const month = pad(date.getMonth() + 1);
  const day = pad(date.getDate());
  const hour = pad(date.getHours());
  const min = pad(date.getMinutes());
  const sec = pad(date.getSeconds());
  return year + month + day + hour + min + sec;
};
