import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchCampaignApi } from '@app/services/common/campaign.api';
import { loadingStatus } from '@utils/loadingStatus';
import {
  MemberPiCampaignInformationState,
  CampaignApiResponse,
} from '@dfTypes/user/memberPiCampaignInformation';
import { RootState } from 'src/store';

export enum MemberPiCampaignInformationStateName {
  Name = 'memberPiCampaignInformation',
}

const initialState: MemberPiCampaignInformationState = {
  memberPiCampaignInformationLoading: loadingStatus.idle,
  isCampaignEligible: false,
};

export const getMemberPiCampaignInformation = createAsyncThunk<
  MemberPiCampaignInformationState | any,
  string,
  { state }
>(
  'get/memberPiCampaignInformation',
  async (MBR: string, thunkAPI) => {
    try {
      const campaignResponse = (await fetchCampaignApi(MBR)) as CampaignApiResponse;
      return !!campaignResponse.IsDownloadAppCampaignEligible;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  },
  {
    condition: (_, thunkAPI) => {
      const loading = thunkAPI.getState().memberPiCampaignInformation.loginMemberInformationLoading;
      if ([loadingStatus.pending, loadingStatus.succeeded]?.includes(loading)) {
        return false;
      }
    },
  }
);

const memberPiCampaignInformationSlice = createSlice({
  name: MemberPiCampaignInformationStateName.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMemberPiCampaignInformation.pending, (state) => {
        state.memberPiCampaignInformationLoading = loadingStatus.pending;
      })
      .addCase(getMemberPiCampaignInformation.fulfilled, (state, action) => {
        state.memberPiCampaignInformationLoading = loadingStatus.succeeded;
        state.isCampaignEligible = action.payload;
      })
      .addCase(getMemberPiCampaignInformation.rejected, (state) => {
        state.memberPiCampaignInformationLoading = loadingStatus.failed;
      });
  },
});

type MemberPiCampaignInformationSliceReducer = {
  [MemberPiCampaignInformationStateName.Name]: ReturnType<
    typeof memberPiCampaignInformationSlice.reducer
  >;
};

export const memberPiCampaignInformation = (
  state: RootState<MemberPiCampaignInformationSliceReducer>
) => state?.memberPiCampaignInformation;
export default memberPiCampaignInformationSlice.reducer;
