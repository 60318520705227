import { fetchUnderAgeData } from '@services/common/is-nc17.api';
import { intLastModified60M } from '@utils/initTime';
import { isDev, isOnline } from '@utils/config/env';

export const getR18List = () => {
  return fetchUnderAgeData({
    callback: 'jsonpcb_underage',
    timestamp: intLastModified60M?.toString(),
    version: isOnline || isDev ? 'v2.1' : 'stage',
  }).then((response) => response.NC17 ?? []);
};
