export const gaKeyGenerate = (maxlength, arrGA) => {
  // eslint-disable-next-line no-plusplus
  for (let i = 2; i < maxlength + 1; i++) {
    const key = arrGA[0]?.key + paddingNumber(i, 2);
    arrGA?.push({ key });
  }
  arrGA[0].key += paddingNumber(1, 2);
  return arrGA;
};

const paddingNumber = (number, length) => {
  return number?.toString()?.padStart(length, '0');
};
