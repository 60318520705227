import axios from 'axios';
import type { CampaignApiResponse } from '@dfTypes/loginMemberInformation';
import { ecapiUrl } from '../utils/domain-route';

async function fetchCampaignApi(MBR: string): Promise<CampaignApiResponse> {
  const url = ecapiUrl({
    path: `/fsapi/marketing/campaign/journey/status/${MBR}`,
    isNormal: true,
  });

  return axios
    .get(url, { withCredentials: true })
    .then((response) => response) as Promise<CampaignApiResponse>;
}

export { fetchCampaignApi };
