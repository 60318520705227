import { ProdMedicState } from '@dfTypes/prodMedic';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProdMedic } from '@services/prod/ProdMedic';
import { getFullImgDomain } from '@utils/getFullImgDomain';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { getShelfLife } from '@commonFeatures/desc/transform/getShelfLife.transformer';

export enum ProdMedicNameState {
  Name = 'prodMedic',
}

const initialState: ProdMedicState = {
  prodMedicLoading: loadingStatus.idle,
  prodMedicContent: [],
  addProdProdMedicContent: [],
};

const FETCH_PROD_MEDIC: string = 'prod/fetchProdMedic';

export const getProdMedic = createAsyncThunk(
  FETCH_PROD_MEDIC,
  async (obj: { prodIdArr: string[]; prodNameArr: string[]; isAddProd?: boolean }) => {
    const { prodIdArr, prodNameArr, isAddProd } = obj;
    const taskList = prodIdArr.map(async (prodId, index) => {
      // eslint-disable-next-line no-return-await
      return await fetchProdMedic({
        callback: `jsonp_medic${index}`,
        version: 'v2',
        prodId,
      });
    });

    const refactorMedicContent = Promise.all(taskList).then((medicContent) => {
      return {
        refactorMedicContent: medicContent?.map((item, index) => {
          return {
            Name: prodNameArr[index],
            ShelfLifeLabel: getShelfLife(item.ShelfLife),
            ...item,
            Indications: {
              ...item.Indications,
              Pic: {
                P01: getFullImgDomain(item.Indications.Pic.P01),
                P02: getFullImgDomain(item.Indications.Pic.P02),
                P03: getFullImgDomain(item.Indications.Pic.P03),
                P04: getFullImgDomain(item.Indications.Pic.P04),
              },
            },
            Notice: {
              ...item.Notice,
              Pic: {
                P01: getFullImgDomain(item.Notice.Pic.P01),
                P02: getFullImgDomain(item.Notice.Pic.P02),
                P03: getFullImgDomain(item.Notice.Pic.P03),
                P04: getFullImgDomain(item.Notice.Pic.P04),
              },
            },
            OuterPacking: {
              P01: getFullImgDomain(item.OuterPacking.P01),
              P02: getFullImgDomain(item.OuterPacking.P02),
              P03: getFullImgDomain(item.OuterPacking.P03),
              P04: getFullImgDomain(item.OuterPacking.P04),
            },
          };
        }),
        isAddProd,
      };
    });
    return refactorMedicContent;
  }
);

const prodMedicSlice = createSlice({
  name: ProdMedicNameState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProdMedic.pending, (state) => {
        state.prodMedicLoading = loadingStatus.pending;
        state.prodMedicContent = [];
        state.addProdProdMedicContent = [];
      })
      .addCase(getProdMedic.fulfilled, (state, action) => {
        state.prodMedicLoading = loadingStatus.succeeded;
        if (action.payload.isAddProd) {
          state.addProdProdMedicContent = action.payload.refactorMedicContent;
        } else {
          state.prodMedicContent = action.payload.refactorMedicContent;
        }
      })
      .addCase(getProdMedic.rejected, (state) => {
        state.prodMedicLoading = loadingStatus.failed;
        state.prodMedicContent = [];
        state.addProdProdMedicContent = [];
      });
  },
});

type ProdMedicSliceReducer = {
  [ProdMedicNameState.Name]: ReturnType<typeof prodMedicSlice.reducer>;
};

export const prodMedic = (state: RootState<ProdMedicSliceReducer>) => state.prodMedic;
export default prodMedicSlice.reducer;
