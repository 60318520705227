import { TBurgerSign, IBurgerMenu, TBurgerRegion } from '@dfTypes/store/burgerMenu/View';
import { TBurgerMenuResponse } from '@dfTypes/store/burgerMenu/Response';
import handleRegionUrl from './handleRegionUrl';
import handleSignUrl from './handleSignUrl';

const tidyResponseBurgerMenu = (response: TBurgerMenuResponse): IBurgerMenu => {
  const tidyBurgerSign: TBurgerSign[] = [];
  response.map((burSignItem) => {
    if (burSignItem.Nodes.length === 0) return;
    const brandFlagship: any = [];
    const relatedCategories: any = [];
    burSignItem.Nodes.map((burRegionItem) => {
      const obj: TBurgerRegion = {
        Id: burRegionItem.Id,
        Name: burRegionItem.Name,
        Url: handleRegionUrl(burRegionItem.Url, burRegionItem.Id),
        Icon: burRegionItem.Icon,
        gtmName: `${burSignItem.Id.split('/')[1]}_${burRegionItem.Id}_${burRegionItem.Name}`,
        gtmLocationBlock: 'mega_menu',
        gtmLocationNode: burRegionItem.Type === 1 ? 'brand' : 'region',
      };

      if (burRegionItem.Type === 1) {
        return brandFlagship.push(obj);
      }
      return relatedCategories.push(obj);
    });
    if (burSignItem.Id.split('/')[0] === 'h24') {
      return tidyBurgerSign.push({
        Id: burSignItem.Id,
        Name: burSignItem.Name,
        Url: handleSignUrl(burSignItem.Id),
        gtmName: burSignItem.Id.split('/')[1],
        gtmLocationBlock: 'sign_roadmap',
        burgerRegion: {
          relatedCategories: {
            type: `${burSignItem.Id}Category`,
            typeName: '相關分類',
            Nodes: relatedCategories,
          },
          brandFlagship: {
            type: `${burSignItem.Id}Brand`,
            typeName: '品牌旗艦',
            Nodes: brandFlagship,
          },
        },
      });
    }
  });
  return {
    currentSignId: tidyBurgerSign[0].Id,
    burgerSign: tidyBurgerSign,
  };
};
export default tidyResponseBurgerMenu;
