import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import fetchHotkeyword from '@services/store/hotkeyword.api';
import { ISearchMenu } from '@dfTypes/store/searchMenu/View';

const LOCALSTORAGE_NAME = 'recentSearchHistoryItems';

export enum SearchMenuState {
  Name = 'searchMenu',
}

const recentSearchHistoryItems = localStorage.getItem(LOCALSTORAGE_NAME)
  ? JSON.parse(localStorage.getItem(LOCALSTORAGE_NAME) || '')
  : [];

const setRecentSearchHistoryItems = (items) => {
  localStorage.setItem(LOCALSTORAGE_NAME, JSON.stringify(items));
};

const deleteRecentSearchHistoryItems = () => {
  localStorage.removeItem(LOCALSTORAGE_NAME);
};

const initialState: ISearchMenu = {
  searchMenuLoading: loadingStatus.idle,
  recentSearchHistory: recentSearchHistoryItems,
  dailyRecommend: [],
  hotSearch: [],
  isSearchHistoryEdit: false,
  errorMessage: '',
};
const FETCH_HOTKEYWORD = 'get/fetchHotkeyword';

export const getSearchMenu = createAsyncThunk(FETCH_HOTKEYWORD, async (_, thunkAPI) => {
  try {
    return await fetchHotkeyword().then(({ Suggestion, HotKeyword }) => {
      return {
        dailyRecommend: Suggestion.map((item, idx) => {
          return {
            Id: idx,
            Name: item,
          };
        }),
        hotSearch: HotKeyword.map((item, idx) => {
          return {
            Id: idx,
            Name: item,
          };
        }),
      };
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const searchMenuSlice = createSlice({
  name: SearchMenuState.Name,
  initialState,
  reducers: {
    addRecentSearchHistoryItems(state, action) {
      const previousHistories = state.recentSearchHistory.filter(
        (keyword) => keyword.Name !== action.payload.Name
      );
      state.recentSearchHistory = [action.payload, ...previousHistories].slice(0, 20);
      setRecentSearchHistoryItems(state.recentSearchHistory);
    },
    removeRecentSearchHistoryItems(state, action) {
      const items = state.recentSearchHistory.filter((item) => item.Id !== action.payload.Id);
      state.recentSearchHistory = items;
      setRecentSearchHistoryItems(items);
    },
    deleteAllRecentSearchHistoryItems(state) {
      state.recentSearchHistory = [];
      deleteRecentSearchHistoryItems();
    },
    setIsSearchHistoryEdit: (state, action) => {
      state.isSearchHistoryEdit = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchMenu.pending, (state) => {
        state.searchMenuLoading = loadingStatus.pending;
        state.dailyRecommend = [];
        state.hotSearch = [];
      })
      .addCase(getSearchMenu.fulfilled, (state, action) => {
        state.searchMenuLoading = loadingStatus.succeeded;
        state.dailyRecommend = action.payload.dailyRecommend;
        state.hotSearch = action.payload.hotSearch;
      })
      .addCase(getSearchMenu.rejected, (state, action) => {
        state.searchMenuLoading = loadingStatus.failed;
        state.dailyRecommend = [];
        state.hotSearch = [];
        state.errorMessage = action.payload;
      });
  },
});

type SearchMenuSliceReducer = {
  [SearchMenuState.Name]: ReturnType<typeof searchMenuSlice.reducer>;
};

export const {
  addRecentSearchHistoryItems,
  removeRecentSearchHistoryItems,
  deleteAllRecentSearchHistoryItems,
  setIsSearchHistoryEdit,
} = searchMenuSlice.actions;
export const searchMenu = (state: RootState<SearchMenuSliceReducer>) => state?.searchMenu;
export default searchMenuSlice.reducer;
