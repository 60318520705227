import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';

export enum CommonHeaderMenuState {
  Name = 'commonHeaderMenu',
}

const initialState = {
  tempMenuList: {},
};

const commonHeaderSlice = createSlice({
  name: CommonHeaderMenuState.Name,
  initialState,
  reducers: {
    addCallApiMenuList(state, action) {
      const newTempMenuList = { ...state.tempMenuList, ...action.payload };
      state.tempMenuList = newTempMenuList;
    },
  },
});

type CommonHeaderSlice = {
  [CommonHeaderMenuState.Name]: ReturnType<typeof commonHeaderSlice.reducer>;
};

export const { addCallApiMenuList } = commonHeaderSlice.actions;
export const tempMenuList = (state: RootState<CommonHeaderSlice>) =>
  state?.commonHeaderMenu?.tempMenuList ?? {};
export default commonHeaderSlice.reducer;
