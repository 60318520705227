export const toLimitQty = ({ prodDetail, specData, limitCombineQty }) => {
  const { isCombine, isSpec } = prodDetail;
  const mainSpecQty =
    isSpec === 1 ? specData?.filter((filter) => filter.Qty > 0)?.[0]?.Qty ?? 0 : 0;

  if (isSpec === 1 && isCombine === 0) return mainSpecQty;
  if (isCombine === 1) return limitCombineQty;

  return prodDetail.Qty;
};
