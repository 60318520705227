import { ProdAddState, ProdAddResponse, AddOnProd } from '@dfTypes/prodAdd';
import { TProdDetail } from '@dfTypes/prodInformation';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProdAddItem } from '@services/prod/prodAddItem.api';
import { loadingStatus } from '@utils/loadingStatus';
import { toLimitQty } from '@commonFeatures/additional/transform/toLimitQty.transformer';
import { toMainProductCardData } from '@commonFeatures/additional/transform/toMainProductCardData.transformer';
import { toAddOnProductCardData } from '@commonFeatures/additional/transform/toAddOnProductCardData.transformer';
import { RootState } from 'src/store';
import { fetchMultipleProdInformation } from '@services/common/multipleProdInformation.api';
import { intLastModified10M } from '@utils/initTime';

export enum ProdAddNameState {
  Name = 'prodAdd',
}

const initialState: ProdAddState = {
  prodAddLoading: loadingStatus.idle,
  limitQty: 0,
  prodAddList: [],
  mainProdData: [],
  addOnProdData: [],
  errorMessage: '',
  isAdditionalBlockShow: false,
};
const FETCH_PROD_ADD: string = 'prod/fetchProdAdd';

export const getProdAdd = createAsyncThunk(
  FETCH_PROD_ADD,
  async (
    obj: {
      prodId: string;
      prodDetail: TProdDetail;
      spec;
      limitCombineQty: number;
    },
    thunkAPI
  ) => {
    const { prodId, prodDetail, spec, limitCombineQty } = obj;
    const limitQty = toLimitQty({
      prodDetail,
      specData: spec,
      limitCombineQty,
    });

    try {
      const data = await fetchProdAddItem({
        version: 'v2',
        prodId: prodId.slice(0, 16), // 16碼
      }).then(async (res) => {
        const response: ProdAddResponse = res;
        const prodAddList: string[] = Object.keys(res);
        const mainProdData: AddOnProd[] = toMainProductCardData({
          data: prodDetail,
          specData: spec,
          limitQty,
        });

        let addOnProdDetail;
        if (prodAddList?.length > 0) {
          addOnProdDetail = await fetchMultipleProdInformation({
            timestamp: intLastModified10M?.toString(),
            prodId: prodAddList?.join(','),
            callback: '_callback_prod',
            fields: 'isCombine,isDescAndIntroSync,isFoodContents,isMedical',
            count: 0,
          });
        }
        const addOnProdData: AddOnProd[] = toAddOnProductCardData({
          data: response,
          prodAddList,
          addOnProdDetail,
        });

        // 是否在Header上顯示加價購錨點(加價購商品存在且主商品有庫存且主商品不為已售完SoldOutOR商未開賣時NotReady)
        const isAdditionalBlockShow: boolean =
          addOnProdData.length > 0 &&
          addOnProdData.filter((filterItem) => filterItem.specList.length > 0)?.length > 0;

        return { prodAddList, mainProdData, addOnProdData, limitQty, isAdditionalBlockShow };
      });

      return data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const prodAddSlice = createSlice({
  name: ProdAddNameState.Name,
  initialState,
  reducers: {
    changeLimitQty(state, action) {
      state.limitQty = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProdAdd.pending, (state) => {
        state.prodAddLoading = loadingStatus.pending;
        state.limitQty = 0;
        state.prodAddList = [];
        state.mainProdData = [];
        state.addOnProdData = [];
        state.isAdditionalBlockShow = false;
        state.errorMessage = '';
      })
      .addCase(getProdAdd.fulfilled, (state, action) => {
        state.prodAddLoading = loadingStatus.succeeded;
        state.limitQty = action.payload.limitQty;
        state.prodAddList = action.payload.prodAddList;
        state.mainProdData = action.payload.mainProdData;
        state.addOnProdData = action.payload.addOnProdData;
        state.isAdditionalBlockShow = action.payload.isAdditionalBlockShow;
        state.errorMessage = '';
      })
      .addCase(getProdAdd.rejected, (state, action) => {
        state.prodAddLoading = loadingStatus.failed;
        state.limitQty = 0;
        state.prodAddList = [];
        state.mainProdData = [];
        state.addOnProdData = [];
        state.isAdditionalBlockShow = false;
        state.errorMessage = action.payload;
      });
  },
});

type ProdAddSliceReducer = {
  [ProdAddNameState.Name]: ReturnType<typeof prodAddSlice.reducer>;
};

export const { changeLimitQty } = prodAddSlice.actions;
export const prodAdd = (state: RootState<ProdAddSliceReducer>) => state.prodAdd;
export default prodAddSlice.reducer;
