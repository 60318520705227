// http://ecapi2.dev.mypchome.com.tw/fsapi/ecshop/composition/sign/v1/book/menu/data
import axios from 'axios';
import { HeaderMenuBannerApiResponse, HeaderMenuBannerData } from '@dfTypes/header/headerMenu';
import { toHeaderSignBanner } from '@commonFeatures/header/transform/toHeaderSignBanner.transformer';
import { ecapiUrl } from '../utils/domain-route';

type Options = {
  signId: string;
  op: string | null;
  ts: string | null;
};

async function fetchHeaderMenuBanner(options: Options): Promise<HeaderMenuBannerData> {
  const { signId = '', op = '', ts = '' } = options;
  const isPreview: boolean = op !== null && ts !== null;
  const queryOpTs = isPreview ? `?op=${op}&ts=${ts}` : '';
  const url = ecapiUrl({
    path: `fsapi/ecshop/composition/sign/v1/${signId}/menu/data${queryOpTs}`,
  });

  try {
    const response = (await axios
      .get(url)
      .then((res) => res)) as unknown as HeaderMenuBannerApiResponse;
    return toHeaderSignBanner({ response, signId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('header menu banner api error');
    return {};
  }
}

export { fetchHeaderMenuBanner };
