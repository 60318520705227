import { ecwebUrl } from '@services/utils/domain-route';
import { IHeaderMenuNodes } from '@dfTypes/header/headerMenu';

const toCountCateItemTotal = (data): number => {
  const cateItems = data.filter((item) => item.Nodes.length > 0);
  return cateItems.length;
};

export const getTidyMenuData = (res) => {
  const cateTotalItemTotal = toCountCateItemTotal(res);
  const ONE_ROW_CHAR_LIMIT = 147;
  const SHOW_ROW_LIMIT = 19 - cateTotalItemTotal;
  const TOTAL_SHOW_CHAR = ONE_ROW_CHAR_LIMIT * SHOW_ROW_LIMIT;
  let totalChar = ONE_ROW_CHAR_LIMIT * cateTotalItemTotal; // 預設標題四個字為一行長度(品牌旗艦 or 相關分類)

  const newData = res.map((item: { Nodes: IHeaderMenuNodes }) => {
    const { Nodes } = item;
    const newNodes = Nodes?.map((element, index: number) => {
      const { Id, Name } = element;
      const url = ecwebUrl({ path: `/region/${Id}`, isPageUrl: true });
      // charPixel : 中英數字空白皆11px, 分隔線 1px ,padding共16px，最後一筆沒有分隔線
      const charPixel = index === Nodes?.length - 1 ? Name.length * 11 + 16 : Name.length * 11 + 17;
      totalChar += charPixel;
      if (index <= 1 || totalChar <= TOTAL_SHOW_CHAR) {
        return { ...element, url, isShow: true };
      }
      return { ...element, url, isShow: false };
    });
    return { ...item, Nodes: newNodes };
  });
  return newData;
};
