// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/cateapi/v1.5/sign&region=DSBE&_callback=cb_ecshopCategorySign&462819?_callback=cb_ecshopCategorySign
import { TEcshopCategorySignSign } from '@dfTypes/ecshopCategory';
import Fetch from '../utils/jsonp';
import { ecapiUrl } from '../utils/domain-route';

interface OptionsType {
  regionId: string;
}

async function fetchEcshopCategorySign(options: OptionsType): Promise<TEcshopCategorySignSign[]> {
  const { regionId } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/cateapi/v1.5/sign&region=${regionId}&_callback=cb_ecshopCategorySign&1`,
  });

  const body = await Fetch.jsonp({
    url,
    callback: 'cb_ecshopCategorySign',
  });
  return body;
}
export { fetchEcshopCategorySign };
