// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/prod/QFAY2M-A9009Y3RL/mdlicense&_callback=jsonp_medic?_callback=jsonp_medic
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface OptionsType {
  prodId: string;
  version: string;
  callback: string;
}

function fetchProdMedic(options: OptionsType): Promise<any> {
  const { prodId, version, callback } = options;
  const url = ecapiUrl({
    path: `/cdn/ecshop/prodapi/${version}/prod/${prodId}/mdlicense&_callback=${callback}`,
  });

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((body) => body);
}

export { fetchProdMedic };
