import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEnterprise } from '@services/common/enterprise.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';

export enum EnterpriseState {
  Name = 'enterprise',
}

const initialState: { enterpriseLoading: string; enterprise: string[]; errorMessage: any } = {
  enterpriseLoading: loadingStatus.idle,
  enterprise: [],
  errorMessage: '',
};

const FETCH_ENTERPRISE = 'get/common/fetchEnterprise';

export const getEnterprise = createAsyncThunk(FETCH_ENTERPRISE, async (_, thunkAPI) => {
  try {
    return await fetchEnterprise();
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message.data);
  }
});

const enterpriseSlice = createSlice({
  name: EnterpriseState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEnterprise.pending, (state) => {
        state.enterpriseLoading = loadingStatus.pending;
      })
      .addCase(getEnterprise.fulfilled, (state, action) => {
        state.enterpriseLoading = loadingStatus.succeeded;
        state.enterprise = action.payload;
        state.errorMessage = '';
      })
      .addCase(getEnterprise.rejected, (state, action) => {
        state.enterpriseLoading = loadingStatus.failed;
        state.enterprise = [];
        state.errorMessage = action.payload;
      });
  },
});

type EnterpriseSliceReducer = {
  [EnterpriseState.Name]: ReturnType<typeof enterpriseSlice.reducer>;
};

export const enterprise = (state: RootState<EnterpriseSliceReducer>) => state.enterprise;
export default enterpriseSlice.reducer;
