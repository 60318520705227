import { isDev, isStage, isOnline, reactAppEnv } from '@utils/config/env';

type TEnvsProps = {
  dev?: string;
  stage?: string;
  online?: string;
  other: string;
};
interface TOptionProps extends TEnvsProps {
  sid?: string;
}

export const renderCdnVersion = ({ dev, stage, online, other }: TEnvsProps) => {
  const version = (() => {
    switch (reactAppEnv) {
      case 'dev':
        return dev ?? other;
      case 'stage':
        return stage ?? other;
      case 'online':
        return online ?? other;
      default:
        return other;
    }
  })();

  if (!version) return '';
  return `${version}/`;
};

export const renderVersion = ({ sid, dev, stage, online, other }: TOptionProps) => {
  if (sid && sid !== '') {
    return 'preview';
  }
  if (isDev) {
    return dev ?? other;
  }
  if (isStage) {
    return stage ?? other;
  }
  if (isOnline) {
    return online ?? other;
  }
  return other;
};
