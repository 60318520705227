import { ProdFoodDescState } from '@dfTypes/prod/prodDesc/View';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProdFoodDesc } from '@services/prod/prodFoodDesc.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { initialFoodDescData } from '@commonFeatures/desc/utils';
import { toFoodContentMeatInfo } from '@commonFeatures/desc/transform/toFoodContentMeatInfo.transformer';

export enum ProdFoodDescNameState {
  Name = 'prodFoodDesc',
}

const initialState: ProdFoodDescState = {
  foodDescLoading: loadingStatus.idle,
  foodDesc: initialFoodDescData,
  addFoodDesc: initialFoodDescData, // 好康加購商品食品規格
};

const FETCH_PROD_FOOD_DESC: string = 'prod/fetchProdFoodDesc';

export const getProdFoodDesc = createAsyncThunk(
  FETCH_PROD_FOOD_DESC,
  async (obj: { prodId: string; isAddProd?: boolean }, thunkAPI) => {
    const { prodId, isAddProd } = obj;
    try {
      const response = await fetchProdFoodDesc({
        version: 'v2',
        prodId,
      });

      // 當有肉品備註
      if (response?.Meat && response?.Meat.length > 0) {
        const newMeatList = toFoodContentMeatInfo(response.Meat);
        return { foodDesc: { ...response, Meat: newMeatList } };
      }
      return { foodDesc: response, isAddProd };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const prodFoodDescSlice = createSlice({
  name: ProdFoodDescNameState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProdFoodDesc.pending, (state) => {
        state.foodDescLoading = loadingStatus.pending;
        state.foodDesc = initialFoodDescData;
        state.addFoodDesc = initialFoodDescData;
      })
      .addCase(getProdFoodDesc.fulfilled, (state, action) => {
        state.foodDescLoading = loadingStatus.succeeded;
        if (action.payload.isAddProd) {
          state.addFoodDesc = action.payload.foodDesc;
        } else {
          state.foodDesc = action.payload.foodDesc;
        }
      })
      .addCase(getProdFoodDesc.rejected, (state) => {
        state.foodDescLoading = loadingStatus.failed;
        state.foodDesc = initialFoodDescData;
        state.addFoodDesc = initialFoodDescData;
      });
  },
});

type ProdFoodDescSliceReducer = {
  [ProdFoodDescNameState.Name]: ReturnType<typeof prodFoodDescSlice.reducer>;
};

export const prodFoodDesc = (state: RootState<ProdFoodDescSliceReducer>) => state.prodFoodDesc;
export default prodFoodDescSlice.reducer;
