import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  fetchDeleteProdTrackButton,
  fetchPostProdTrackButton,
} from '@services/common/prodTrackButton.api';
import { loadingStatus } from '@utils/loadingStatus';
import { IProdTrackButtonState } from '@dfTypes/prodTrackButton';
import { RootState } from 'src/store';

export enum ProdTrackButtonState {
  Name = 'prodTrackButton',
}

const initialState: IProdTrackButtonState = {
  prodTrackButtonLoading: loadingStatus.idle,
  trackButton: 204,
  errorMessage: null,
};

export const postProdTrackButton = createAsyncThunk(
  'post/prodTrackButton',
  async (
    { prodId, memberId, track }: { prodId: string; memberId: string; track: boolean },
    { rejectWithValue }
  ) => {
    try {
      let response;
      if (track) {
        response = await fetchPostProdTrackButton({
          prodId,
          memberId,
        });
      } else {
        response = await fetchDeleteProdTrackButton({
          prodId,
          memberId,
        });
      }
      return response.status;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const prodTrackButtonSlice = createSlice({
  name: ProdTrackButtonState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postProdTrackButton.pending, (state) => {
        state.prodTrackButtonLoading = loadingStatus.pending;
      })
      .addCase(postProdTrackButton.fulfilled, (state, action) => {
        state.prodTrackButtonLoading = loadingStatus.succeeded;
        state.trackButton = action.payload;
      })
      .addCase(postProdTrackButton.rejected, (state, action) => {
        state.prodTrackButtonLoading = loadingStatus.failed;
        state.errorMessage = action.payload;
      });
  },
});

type ProdTrackButtonSliceReducer = {
  [ProdTrackButtonState.Name]: ReturnType<typeof prodTrackButtonSlice.reducer>;
};

export const prodTrackButton = (state: RootState<ProdTrackButtonSliceReducer>) =>
  state?.prodTrackButton;
export default prodTrackButtonSlice.reducer;
