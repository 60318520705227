import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
// dfTypes
import {
  IRegionInformationState,
  TGetRegionInformationThunkProps,
} from '@dfTypes/regionInformation';
// utils
import { loadingStatus } from '@utils/loadingStatus';
// services
import { fetchRegionInformation } from '@services/prod/regionInformation.api';

export enum RegionInformationState {
  Name = 'regionInformation',
}

const initialState: IRegionInformationState = {
  regionLoading: loadingStatus.idle,
  regionInfo: {
    Id: '',
    Name: '',
    isVip: 0,
    isPick: 0,
    Extra: { Amount: 0, Fee: 0 },
    isEbook: 0,
    isClothing: 0,
  },
  errorMessage: null,
};

export const getRegionInformation = createAsyncThunk(
  'get/regionInformation',
  async ({ regionId }: TGetRegionInformationThunkProps, { rejectWithValue }) => {
    try {
      const response = await fetchRegionInformation({
        regionId,
      });
      return { regionInfo: response?.[0] };
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

const regionInformationSlice = createSlice({
  name: RegionInformationState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegionInformation.pending, (state) => {
        state.regionLoading = loadingStatus.pending;
      })
      .addCase(getRegionInformation.fulfilled, (state, action) => {
        state.regionLoading = loadingStatus.succeeded;
        state.regionInfo = action.payload.regionInfo;
      })
      .addCase(getRegionInformation.rejected, (state, action) => {
        state.regionLoading = loadingStatus.failed;
        state.errorMessage = action.payload;
      });
  },
});

type RegionInformationSliceReducer = {
  [RegionInformationState.Name]: ReturnType<typeof regionInformationSlice.reducer>;
};

export const regionInformation = (state: RootState<RegionInformationSliceReducer>) =>
  state?.regionInformation;
export default regionInformationSlice.reducer;
