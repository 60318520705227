import { isStage, reactAppEnv, isNodeProduction } from '@utils/config/env';
import { WINDOW_LOCATION_ORIGIN, apiDomain } from '@services/utils/domain-route';

const currentEnv: string = reactAppEnv || 'default';
const isProd = isNodeProduction;
const domain: string = isStage ? WINDOW_LOCATION_ORIGIN : apiDomain?.layout;
const isDevStageEnv =
  currentEnv === 'dev' &&
  window.location.href.includes('stage') &&
  !window.location.href.includes('localhost');

export { currentEnv, isProd, domain, isDevStageEnv };
