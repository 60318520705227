/**
 * loading status for redux
 */
export const loadingStatus: {
  idle: string;
  pending: string;
  succeeded: string;
  failed: string;
} = {
  idle: 'idle',
  pending: 'pending',
  succeeded: 'succeeded',
  failed: 'failed',
};

export const isLoadingSettled = (status) => {
  return status === loadingStatus.succeeded || status === loadingStatus.failed;
};
