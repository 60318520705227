const isSentryEnabled = process.env.REACT_APP_SENTRY_ENABLE === 'true';
const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
const isAllowPage = window.location.pathname.indexOf('/vip/refund') !== -1;

// only in development
if (sentryDsn && isSentryEnabled && isAllowPage) {
  // Initialize Sentry
  import('@sentry/react').then((Sentry) => {
    console.info('Sentry is active');

    const options = {
      tracesSampleRate: 0, // performance monitoring (doesn't affect errors)
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur. } : {};
    };

    Sentry.init({
      dsn: sentryDsn,
      integrations: [
        new Sentry.BrowserTracing(),
        new Sentry.Replay(),
        Sentry.captureConsoleIntegration({ levels: ['error'] }), // Captures Console Error API calls via `captureException` or `captureMessage` (Automatically generate issues).
        Sentry.extraErrorDataIntegration(),
      ],
      ignoreErrors: [
        'undefined is not an object (evaluating \'r["@context"].toLowerCase\')',
        'undefined is not an object (evaluating \'r[0]["@context"]\')',
      ],
      ...options,
    });
  });
}
