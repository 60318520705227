import { isDevStageEnv } from '@app/utils/eipLayout/config';
import { isDev } from '@utils/config/env';

const WINDOW_LOCATION_ORIGIN = window.location.origin;
const SHOPPING = 'https://shopping.pchome.com.tw';
const API = 'https://24h.pchome.com.tw';
const M = 'https://24h.m.pchome.com.tw';
const DEV_ECAPI2 = 'http://ecapi2.dev.mypchome.com.tw';
const DEV_API = 'http://24h1.dev.mypchome.com.tw';
const DEV_M = 'http://24h1.m.dev.mypchome.com.tw';

const apiDomain = {
  layout: isDev ? DEV_API : API,
  shopping: SHOPPING,
  video: isDev ? DEV_M : M,
};

const safeUrl = (path: string) => {
  return path.replace(/^\/|\/$/g, '');
};

// 24h domain
const ecwebUrl = ({ path, isPageUrl }: { path: string; isPageUrl?: boolean }) => {
  const safePath = safeUrl(path);
  const ecwebDomain = process.env.REACT_APP_API_DOMAIN_ECWEB;

  if (isPageUrl) {
    return `${window.location.origin}/${safePath}`;
  }
  // 拉拉熊dev的stage環境 => isDevStageEnv
  if (isDev || isDevStageEnv) {
    return `http://24h1.dev.mypchome.com.tw/${safePath}`;
  }

  if (ecwebDomain) {
    return `${ecwebDomain}/${safePath}`;
  }
  return `${window.location.origin}/${safePath}`;
};

const ecshwebUrl = (path: string) => {
  const safePath = safeUrl(path);

  return `${process.env.REACT_APP_API_DOMAIN_ECSHWEB}/${safePath}`;
};

const ecapiUrl = ({ path, isNormal }: { path: string; isNormal?: boolean }) => {
  const safePath = safeUrl(path);

  if (isNormal) return `${process.env.REACT_APP_API_DOMAIN_ECAPI_NO_CDN}/${safePath}`;
  return `${process.env.REACT_APP_API_DOMAIN_ECAPI}/${safePath}`;
};

// 購物車相關
const ecsslUrl = ({
  path,
  isEcsslCart,
  isPageUrl,
}: {
  path: string;
  isEcsslCart?: boolean;
  isPageUrl?: boolean;
}): string => {
  const safePath = safeUrl(path);
  if (isPageUrl) {
    return `${window.location.origin}/${safePath}`;
  }
  if (isEcsslCart) return `${process.env.REACT_APP_API_DOMAIN_ECSSL_CART}/${safePath}`;
  return `${process.env.REACT_APP_API_DOMAIN_ECSSL}/${safePath}`;
};

// 顧客中心相關
const ecvipUrl = (path: string) => {
  const safePath = safeUrl(path);

  return `${process.env.REACT_APP_API_DOMAIN_ECVIP}/${safePath}`;
};

export {
  apiDomain,
  WINDOW_LOCATION_ORIGIN,
  DEV_ECAPI2,
  ecwebUrl,
  ecshwebUrl,
  ecapiUrl,
  ecsslUrl,
  ecvipUrl,
};
