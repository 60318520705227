import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLastProd } from '@services/common/last-prod.api';
import { getFullImgDomain } from '@utils/getFullImgDomain';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import {
  LastProdDataResponse,
  PersonalLastProdData,
  ProdData,
} from '@dfTypes/homepage/homepageProd';
import { ecwebUrl } from '@services/utils/domain-route';
import { fetchProdButton } from '@app/services/prod/prodButton.api';
import { getDiscountPrice } from '@app/utils/getDiscountPrice';

export enum PersonalLastProdState {
  Name = 'personalLastProd',
}

const initialState: PersonalLastProdData = {
  personalLastProdLoading: loadingStatus.idle,
  personalLastProdList: '',
};

const FETCH_LAST_PROD = 'prod/fetchLastProd';
export const lastProdAsync = createAsyncThunk<ProdData[], string[], { state }>(
  FETCH_LAST_PROD,
  async (item: Array<string>) => {
    const [prodsButton, response] = await Promise.all([
      fetchProdButton({
        version: 'v2',
        prodId: item.join(','),
        fields: 'Id,Price',
        prefix: 'lastProd',
      }),
      fetchLastProd({
        version: 'v2',
        itemList: item,
        callback: 'jsonpcb_prodecshop',
      }),
    ]);

    const prodData =
      Object.keys(response)?.map((prodId: string) => {
        const { Id, Name, Pic }: LastProdDataResponse = response[prodId];
        const { Price } = prodsButton.find((prod) => prod.Id === Id);
        return {
          id: `${Id}`,
          imageSrc: getFullImgDomain(Pic?.S),
          imageAlt: Name,
          link: ecwebUrl({ path: `/prod/${Id}`, isPageUrl: true }),
          price: getDiscountPrice(Price),
        };
      }) ?? [];
    return prodData;
  },
  {
    condition: (_, thunkAPI) => {
      const loading = thunkAPI.getState().personalLastProd.personalLastProdLoading;
      if ([loadingStatus.pending, loadingStatus.succeeded]?.includes(loading)) {
        return false;
      }
    },
  }
);

const lastProdSlice = createSlice({
  name: PersonalLastProdState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(lastProdAsync.pending, (state) => {
        state.personalLastProdLoading = loadingStatus.pending;
      })
      .addCase(lastProdAsync.fulfilled, (state, action) => {
        state.personalLastProdLoading = loadingStatus.succeeded;
        state.personalLastProdList = action.payload;
      })
      .addCase(lastProdAsync.rejected, (state) => {
        state.personalLastProdLoading = loadingStatus.failed;
      });
  },
});

type LastProdSliceReducer = {
  [PersonalLastProdState.Name]: ReturnType<typeof lastProdSlice.reducer>;
};

export const personalLastProd = (state: RootState<LastProdSliceReducer>) => state?.personalLastProd;
export default lastProdSlice.reducer;
