// https://ecapi-cdn.pchome.com.tw/ecshop/v2.1/region,store&isEntArea=1&_callback=jsonp_enterprise&464979?_callback=jsonp_enterprise

import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

async function fetchEnterprise(): Promise<string[]> {
  const url = ecapiUrl({
    path: '/ecshop/v2.1/region,store&isEntArea=1&_callback=jsonp_enterprise&1',
  });

  // eslint-disable-next-line no-return-await
  return await Fetch.jsonp({
    url,
    callback: 'jsonp_enterprise',
  });
}

export { fetchEnterprise };
