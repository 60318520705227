// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/prod/{商品ID}/desc&fields=Id,Stmt,Equip,Remark,Liability,Kword,Slogan,Author,Transman,Pubunit,Pubdate,Approve,Meta&_callback=jsonp_desc?_callback=jsonp_desc
import { ProdDescResponse } from '@dfTypes/prod/prodDesc/Response';
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface OptionsType {
  prodId: string;
  version: string;
  token?: string;
  callback?: string;
}

// online : https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/prod/desc&id=DBCP16-1900FUQRK,DBCP16-A900FTDXE&fields=Id,Stmt,Equip,Remark,Liability,Kword,Slogan,Author,Transman,Pubunit,Pubdate,Approve,Meta&_callback=jsonp_desc?_callback=jsonp_desc
// stage : https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/stage/prod/desc&id=DBCP16-1900FUQRK,DBCP16-A900FTDXE&fields=Id,Stmt,Equip,Remark,Liability,Kword,Slogan,Author,Transman,Pubunit,Pubdate,Approve,Meta&_callback=jsonp_desc?_callback=jsonp_desc
// preview : https://ecapi2.pchome.com.tw/ecshop/prodapi/v2/preview/prod/DIBMZJ-A900FY5Z1/desc&token=8891bca6f0bd9a3340ec6fac168a9f679829f3f3&fields=Id,Stmt,Equip,Remark,Liability,Kword,Slogan,Author,Transman,Pubunit,Pubdate,Approve,Meta&_callback=jsonp_desc?_callback=jsonp_desc
function fetchProdDesc(options: OptionsType): Promise<ProdDescResponse> {
  const { prodId, version, token, callback = 'jsonp_desc' } = options;
  const fields =
    'Id,Stmt,Equip,Remark,Liability,Kword,Slogan,Author,Transman,Pubunit,Pubdate,Approve,Meta,SloganInfo,RltProdInfo';

  let url = '';
  if (version === 'preview') {
    url = `https://ecapi2.pchome.com.tw/ecshop/prodapi/v2/preview/prod/${prodId}/desc&token=${token}&fields=${fields}&_callback=${callback}`;
  } else {
    url = ecapiUrl({
      path: `/cdn/ecshop/prodapi/${version}/prod/${prodId}/desc&fields=${fields}&_callback=${callback}`,
    });
  }

  return Fetch.jsonp({
    url,
    callback: `${callback}`,
  }).then((body) => body);
}
export { fetchProdDesc };
