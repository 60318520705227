/* eslint-disable no-return-await */
import { TRecommendProdInfo } from '@dfTypes/recommend';
import { fetchProdAlso } from '@services/prod/recommend/prodAlso.api';

const PROD_SHOW_LIMIT = 36; // 顯示上限

const prodInfo = (item) => {
  return fetchProdAlso({
    callback: 'jsonp_prod_also',
    version: 'v2',
    prodId: item,
  }).then((obj) => Object.values(obj));
};

const getTask = async (things: string[]) => {
  const result: any = [];
  for (const thing of things) {
    // eslint-disable-next-line no-await-in-loop
    const data = await prodInfo(thing);
    result.push(data);
  }
  return result;
};

const getRecommendInfo = async (recomIdGroup: string[]) => {
  const task = await getTask(recomIdGroup);
  const result = Promise.all(task)
    .then((res) => {
      const list: TRecommendProdInfo[] = [];
      res
        .filter((item) => item)
        .map((elem) => {
          return list.push(...elem);
        });
      return list.slice(0, PROD_SHOW_LIMIT);
    })
    .catch((e) => {
      return [];
    });
  return result;
};
export default getRecommendInfo;
