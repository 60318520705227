import logger from 'redux-logger';
import { isOnline } from '@utils/config/env';
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { commonReducers } from './reducers/commonReducers';
import { cmsReducers } from './reducers/cmsReducers';

let loggerMiddleware: any = [];
if (process.env.NODE_ENV === 'development') {
  loggerMiddleware = [logger];
}

const store = configureStore({
  reducer: { ...commonReducers, ...cmsReducers },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(loggerMiddleware),
  devTools: !isOnline, // redux dev tool 開關
});

export function injectReducer(reducer) {
  const asyncReducers = {};
  for (const [key, value] of Object.entries(reducer)) {
    asyncReducers[key] = value;
  }
  const newRootReducer = combineReducers({
    ...commonReducers,
    ...cmsReducers,
    ...asyncReducers,
  });
  store.replaceReducer(newRootReducer);
}

export type RootState<T> = ReturnType<typeof store.getState> & T;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
