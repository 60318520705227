import { fetchBrowseRecommend } from '@services/prod/recommend/browseRecommend.api';
import { fetchItemRecommend } from '@services/prod/recommend/itemRecommend.api';
import { fetchCollocationRecommend } from '@services/prod/recommend/collocationRecommend.api';
import { fetchSimilarRecommend } from '@services/prod/recommend/similarRecommend.api';
import {
  toRecommendAlsoBuyList,
  toRecommendIdList,
  toRecommendOutfitIdList,
} from '../transform/toRecommendIdList.transformer';
import { toSliceProdIdList } from '../transform/toSliceProdIdList.transformer';
import { toRecommendCard, toRecommendOutfitCard } from '../transform/toRecommendCard.transformer';
import getRecommendInfo from './getRecommendInfo';
import { getRegionList } from './getRegionList';
import { recommendConfig } from '.';

export const getAllRecommendTask = async (obj): Promise<any> => {
  const { prodId, regionId, isNC17, signId, isClothing, R18List } = obj;

  // 別人也看
  const alsoView = await fetchBrowseRecommend({
    callback: 'jsonp_browserecommend',
    version: 'v2',
    prodId,
  })
    .then((response) => {
      if (!response || response.length < 3) return [];
      return toRecommendIdList({
        isNC17,
        R18List,
        productList: response,
      });
    })
    .then(async (response) => {
      const smallGroupForTen = toSliceProdIdList(response);
      const infoData = await getRecommendInfo(smallGroupForTen);
      const recommendCard = toRecommendCard({
        id: '1',
        data: infoData,
        title: recommendConfig[0].title,
        gtmItemList: recommendConfig[0].id,
      });
      return recommendCard;
    });

  // 別人也買
  const alsoBuy = await fetchItemRecommend({
    callback: 'jsonp_itemrecommend',
    version: 'v2',
    prodId,
  })
    .then(async (response) => {
      if (!response || response.length < 3) return [];
      // 目前路標內區Id(品牌+分類)
      const regionList = await getRegionList({ signId });
      return toRecommendAlsoBuyList({
        regionId,
        isNC17,
        R18List,
        regionList,
        productList: response,
      });
    })
    .then(async (response) => {
      const smallGroupForTen = toSliceProdIdList(response);
      const infoData = await getRecommendInfo(smallGroupForTen);
      const recommendCard = toRecommendCard({
        id: '2',
        data: infoData,
        title: recommendConfig[1].title,
        gtmItemList: recommendConfig[1].id,
      });
      return recommendCard;
    });

  if (isClothing) {
    // 相似商品
    const similar = await fetchSimilarRecommend({
      callback: 'jsonp_similarrecommend',
      version: 'v2',
      prodId,
    })
      .then((response) => {
        if (!response || response.length < 3) return [];
        return toRecommendIdList({
          isNC17,
          R18List,
          productList: response,
        });
      })
      .then(async (response) => {
        const smallGroupForTen = toSliceProdIdList(response);
        const infoData = await getRecommendInfo(smallGroupForTen);
        const recommendCard = toRecommendCard({
          id: '3',
          data: infoData,
          title: recommendConfig[2].title,
          gtmItemList: recommendConfig[2].id,
        });
        return recommendCard;
      });

    // 建議搭配
    const outfit = await fetchCollocationRecommend({
      callback: 'jsonp_collocationrecommend',
      version: 'v2',
      prodId,
    })
      .then((response) => {
        if (!response || response.length < 3) return [];
        return [
          toRecommendOutfitIdList({
            isNC17,
            R18List,
            productList: response,
          }),
          response,
        ];
      })
      .then(async (response) => {
        const idList = response[0] ?? [];
        const styleList = response[1] ?? [];
        const smallGroupForTen = toSliceProdIdList(idList);
        const infoData = await getRecommendInfo(smallGroupForTen);
        const recommendCard = toRecommendOutfitCard({
          id: '4',
          data: infoData,
          styleList,
          title: recommendConfig[3].title,
          gtmItemList: recommendConfig[3].id,
        });
        return recommendCard;
      });
    return [alsoView, alsoBuy, similar, outfit];
  }

  return [alsoView, alsoBuy];
};
