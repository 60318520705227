import { TEcshopCategorySignState, TEcshopCategorySignSign } from '@dfTypes/ecshopCategory';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchEcshopCategorySign } from '@services/common/ecshopCategorySign.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';

export enum EcshopCategorySignState {
  Name = 'ecshopCategorySign',
}

const initialState: TEcshopCategorySignState = {
  signLoading: loadingStatus.idle,
  sign: {
    Id: '', // 'h24/ce',
    Name: '',
    Url: '',
  },
};
const FETCH_ECSHOP_CATEGORY_SIGN = 'get/fetchEcshopCategorySign';

export const getEcshopCategorySign = createAsyncThunk(
  FETCH_ECSHOP_CATEGORY_SIGN,
  async (regionId: string, thunkAPI) => {
    try {
      const response: TEcshopCategorySignSign[] = await fetchEcshopCategorySign({
        regionId,
      });
      const newResponse = response?.map((signInfo) => {
        return {
          Id: signInfo.Id,
          Name: signInfo.Name,
          Url: `/sign/${signInfo?.Id?.replace('h24/', '')}`,
        };
      });

      return response?.length > 0 ? newResponse[0] : { Id: '', Name: '', Url: '' };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const ecshopCategorySignSlice = createSlice({
  name: EcshopCategorySignState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEcshopCategorySign.pending, (state) => {
        state.signLoading = loadingStatus.pending;
        state.sign = { Id: '', Name: '', Url: '' };
      })
      .addCase(getEcshopCategorySign.fulfilled, (state, action) => {
        state.signLoading = loadingStatus.succeeded;
        state.sign = action.payload;
      })
      .addCase(getEcshopCategorySign.rejected, (state) => {
        state.signLoading = loadingStatus.failed;
        state.sign = { Id: '', Name: '', Url: '' };
      });
  },
});

type EcshopCategorySignSliceReducer = {
  [EcshopCategorySignState.Name]: ReturnType<typeof ecshopCategorySignSlice.reducer>;
};

export const sign = (state: RootState<EcshopCategorySignSliceReducer>) =>
  state.ecshopCategorySign.sign;
export const ecshopCategorySign = (state: RootState<EcshopCategorySignSliceReducer>) =>
  state.ecshopCategorySign;
export default ecshopCategorySignSlice.reducer;
