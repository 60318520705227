export const recommendConfig = [
  { id: 'recom_alsoview', title: '別人也看' },
  { id: 'recom_alsobuy', title: '別人也買' },
  { id: 'recom_similarproduct', title: '相似商品' },
  { id: 'recom_outfit', title: '建議搭配' },
];

export const beddingValidRegion = [
  'DEAT',
  'DEBC',
  'DEAB',
  'DEBH',
  'DEBA',
  'DEAZ',
  'DEAI',
  'DEAS',
  'DEBG',
  'DEBF',
  'DEBU',
  'DEAX',
  'DEBD',
  'DEBO',
];

export const isNotBookRegion = (element: string): boolean => {
  return element !== 'DJ' && element !== 'DN';
};
