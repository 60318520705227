/* eslint-disable array-callback-return */
import { ecapiUrl } from '@services/utils/domain-route';
import Fetch from '@services/utils/jsonp';

const fetchRegionMenu = ({ signId }) => {
  try {
    return Fetch.jsonp({
      url: ecapiUrl({
        path: `/cdn/ecshop/cateapi/v1.6/sign/${signId}/menu&_callback=cb_ecshopCategoryRegion`,
      }), // 正式環境
      callback: 'cb_ecshopCategoryRegion',
    }).then((response) => {
      const regionList: string[] = [];
      response.map((item) => {
        const { Nodes } = item;
        Nodes.map((element) => {
          if (!regionList.includes(element.Id) && element.Id) return regionList.push(element.Id);
        });
      });
      return regionList;
    });
  } catch (e) {
    return [];
  }
};
export default fetchRegionMenu;
