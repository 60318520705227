import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchLoginApi, fetchUserAccount } from '@services/common/login.api';
import { loadingStatus } from '@utils/loadingStatus';
import {
  MemberInformationData,
  LoginMemberInformationState,
  LoginMemberInformationResponse,
} from '@dfTypes/loginMemberInformation';
import { RootState } from 'src/store';

export enum LoginMemberInformationStateName {
  Name = 'loginMemberInformation',
}

const initialState: LoginMemberInformationState = {
  loginMemberInformationLoading: loadingStatus.idle,
  isLogin: false,
  loginAccount: '', // api 使用無加碼
  loginAccountLabel: '', // 畫面上顯示有加碼名稱
  cashPoint: 0,
  pCoin: 0,
};

const accountMaker = (data: string, type: string): string => {
  let subString = '';

  if (type === 'email') {
    const account = data?.substring(0, data?.indexOf('@') - 1);
    subString = account?.substring(0, account?.length / 3);
  } else if (type === 'phone') {
    subString = data?.substring(0, 4);
  }

  return subString?.padEnd(9, '*');
};

export const getLoginInformation = createAsyncThunk<
  LoginMemberInformationState | any,
  string,
  { state }
>(
  'get/loginInformation',
  async (memberX, thunkAPI) => {
    // eslint-disable-next-line no-return-await
    try {
      const loginResponse: LoginMemberInformationResponse = await Promise.all([
        fetchLoginApi({
          searchText: 'islogin',
          callback: 'jsonpcb_MemberIsLogin',
        }),
        fetchLoginApi({
          searchText: 'member',
          callback: 'jsnop_memberinfo',
          memberX,
        }),
        fetchUserAccount(),
      ]).then((res: [0 | 1 | boolean, MemberInformationData, string]) => {
        if (res[0] && res[2]) {
          const { LoginEmail, LoginMobile, PCoin, CashPoint } = res[1];
          let loginAccountLabel = '';
          if (LoginEmail && LoginEmail?.length > 0) {
            loginAccountLabel = accountMaker(LoginEmail, 'email');
          } else if (LoginMobile && LoginMobile?.length > 0) {
            loginAccountLabel = accountMaker(LoginMobile, 'phone');
          }
          return {
            isLogin: res[0] === 1,
            loginAccount: res[2],
            loginAccountLabel,
            pCoin: PCoin,
            cashPoint: CashPoint,
          };
        }
        return { isLogin: false, loginAccount: '', loginAccountLabel: '', pCoin: 0, cashPoint: 0 };
      });
      return loginResponse;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  },
  {
    condition: (_, thunkAPI) => {
      const loading = thunkAPI.getState().loginMemberInformation.loginMemberInformationLoading;
      if ([loadingStatus.pending, loadingStatus.succeeded]?.includes(loading)) {
        return false;
      }
    },
  }
);

const loginMemberInformationSlice = createSlice({
  name: LoginMemberInformationStateName.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLoginInformation.pending, (state) => {
        state.loginMemberInformationLoading = loadingStatus.pending;
      })
      .addCase(getLoginInformation.fulfilled, (state, action) => {
        state.loginMemberInformationLoading = loadingStatus.succeeded;
        state.isLogin = action?.payload?.isLogin;
        state.loginAccount = action?.payload?.loginAccount;
        state.loginAccountLabel = action?.payload?.loginAccountLabel;
        state.cashPoint = action?.payload?.cashPoint;
        state.pCoin = action?.payload?.pCoin;
      })
      .addCase(getLoginInformation.rejected, (state) => {
        state.loginMemberInformationLoading = loadingStatus.failed;
      });
  },
});

type LoginMemberInformationSliceReducer = {
  [LoginMemberInformationStateName.Name]: ReturnType<typeof loginMemberInformationSlice.reducer>;
};

export const loginMemberInformation = (state: RootState<LoginMemberInformationSliceReducer>) =>
  state?.loginMemberInformation;
export default loginMemberInformationSlice.reducer;
