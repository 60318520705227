import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import fetchBurgerMenu from '@services/store/burgerMenu.api';
import { IBurgerMenuStatus } from '@dfTypes/store/burgerMenu/View';
import tidyResponseBurgerMenu from './transform/tidyResponseBurgerMenu';

export enum BurgerMenuState {
  Name = 'burgerMenu',
}

const initialState: IBurgerMenuStatus = {
  burgerMenuLoading: loadingStatus.idle,
  currentSignId: '',
  burgerSign: [],
  errorMessage: '',
};
const FETCH_BURGERMENU = 'get/fetchBurgerMenu';

export const getBurgerMenu = createAsyncThunk(FETCH_BURGERMENU, async (_, thunkAPI) => {
  try {
    return await fetchBurgerMenu({
      callback: 'jsonp_fetchBurgerMenu',
    }).then((response) => {
      return tidyResponseBurgerMenu(response);
    });
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const burgerMenuSlice = createSlice({
  name: BurgerMenuState.Name,
  initialState,
  reducers: {
    handleChangeCurrentSign(state, action) {
      state.currentSignId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBurgerMenu.pending, (state) => {
        state.burgerMenuLoading = loadingStatus.pending;
        state.errorMessage = '';
      })
      .addCase(getBurgerMenu.fulfilled, (state, action) => {
        state.burgerMenuLoading = loadingStatus.succeeded;
        state.currentSignId = action.payload.currentSignId;
        state.burgerSign = action.payload.burgerSign;
        state.errorMessage = '';
      })
      .addCase(getBurgerMenu.rejected, (state, action) => {
        state.burgerMenuLoading = loadingStatus.failed;
        state.currentSignId = '';
        state.errorMessage = action.payload;
      });
  },
});

type BurgerMenuSliceReducer = {
  [BurgerMenuState.Name]: ReturnType<typeof burgerMenuSlice.reducer>;
};

export const burgerMenu = (state: RootState<BurgerMenuSliceReducer>) => state?.burgerMenu;
export const { handleChangeCurrentSign } = burgerMenuSlice.actions;
export default burgerMenuSlice.reducer;
