import { isDev } from '@utils/config/env';

/**
 * return image url
 * @param {string} imgPath
 * @param {bool} checkEnv
 * @returns {string} complete image url
 */
const cloudPicPrefix = { itemPic: 'cs-', layoutPic: 'fs-', otherPic: 'fs-' };
// 如果資料是拉拉熊來的 checkEnv = isDev
export const getFullImgDomain = (imgPath, checkEnv = false) => {
  let imgDomain = '';

  if (!imgPath || !cloudPicPrefix) return '';

  // if data in dev -> skip others condition
  if (isDev && checkEnv) {
    if (/\/\/(((cs|fs)-[a-f].ecimg.tw)|(ecimg.dev.mypchome.com.tw))/i.test(imgPath)) return imgPath;
    if (!/^\//i.test(imgPath)) {
      imgPath = `/${imgPath}`;
    }
    imgPath = `//ecimg.dev.mypchome.com.tw${imgPath}`;
    return imgPath;
  }

  // condition0: complete image url
  if (/\/\/(cs|fs)-[a-f].ecimg.tw/i.test(imgPath)) return imgPath;
  if (!/^\//i.test(imgPath)) {
    imgPath = `/${imgPath}`;
  }
  const { itemPic, layoutPic } = cloudPicPrefix;

  if (imgPath.indexOf('//ec1img') === 0 || /[a-f].ecimg.tw/i.test(imgPath)) {
    // condition1: //ec1img.pchome.com.tw OR //[a-f].ecimg.tw 開頭 => replace domain to [b-f]
    imgDomain = imgPath.indexOf('/items') === -1 ? layoutPic : itemPic; // 'fs-' | 'cs-'

    const arrimgDomain = ['b', 'c', 'd', 'e', 'f'];
    const ranNum = Math.floor(Math.random() * arrimgDomain.length);
    const randomDomain = arrimgDomain[ranNum];

    const newDomain = `${imgDomain}${randomDomain}.ecimg.tw`;

    imgPath = imgPath.replace(/ec1img.*tw|[a-f].ecimg.tw/, newDomain);
  } else if (itemPic && imgPath.indexOf('/items') === 0) {
    // condition2: items 開頭 => //cs-a.ecimg.tw
    imgPath = `//cs-a.ecimg.tw${imgPath}`;
  } else if (layoutPic && (imgPath.indexOf('/img') === 0 || imgPath.indexOf('layout') !== -1)) {
    // condition3: 沒有domain (如果img開頭 or 裡面有layout) => //fs-a.ecimg.tw
    imgPath = `//fs-a.ecimg.tw${imgPath}`;
  }

  return imgPath;
};
