// https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/prod/DABQ0F-A900F7W4M/intro&fields=Id,Pic,Pstn,Intro,Sort&_callback=jsonp_intro?_callback=jsonp_intro
import { ecapiUrl } from '../utils/domain-route';
import Fetch from '../utils/jsonp';

interface OptionsType {
  prodId: string;
  version: string;
  token?: string;
}

// preview : https://ecapi2.pchome.com.tw/ecshop/prodapi/v2/preview/prod/DSAUR0-A900FY5BD/intro&token=9f7be8f0e71bd054c77baa043ce8c27c71f21ca2&fields=Id,Pic,Pstn,Intro,Sort&_callback=jsonp_intro?_callback=jsonp_intro
// online : https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/v2/preview/prod/DSAUR0-A900FY5BD/intro&fields=Id,Pic,Pstn,Intro,Sort&_callback=jsonp_intro?_callback=jsonp_intro
// stage : https://ecapi-cdn.pchome.com.tw/cdn/ecshop/prodapi/stage/prod/QAAP9D-A900AMYPC/intro&fields=Id,Pic,Pstn,Intro,Sort&_callback=jsonp_intro?_callback=jsonp_intro
function fetchProdIntro(options: OptionsType): Promise<any> {
  const { prodId, version, token } = options;
  let url = '';
  if (version === 'preview') {
    url = `https://ecapi2.pchome.com.tw/ecshop/prodapi/v2/preview/prod/${prodId}/intro&token=${token}&fields=Id,Pic,Pstn,Intro,Sort&_callback=jsonp_intro`;
  } else {
    url = ecapiUrl({
      path: `/cdn/ecshop/prodapi/${version}/prod/${prodId}/intro&fields=Id,Pic,Pstn,Intro,Sort&_callback=jsonp_intro`,
    });
  }

  return Fetch.jsonp({
    url,
    callback: 'jsonp_intro',
  }).then((body) => body);
}
export { fetchProdIntro };
