import { BestsellersMenu, IRegionMenu, IRegionMenuState } from '@dfTypes/sideMenu';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchRegionMenu } from '@services/sideMenu/regionMenu.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { ecwebUrl } from '@services/utils/domain-route';
import { transformToBestsellerSideMenu } from './transformers/transformToBestsellersSidemenuBlock';

export enum RegionMenuState {
  Name = 'regionMenu',
}

const initialBestsellersState = {
  Id: '',
  Name: '',
  Sort: 0,
  Nodes: [],
  Path: '',
};

const initialState: IRegionMenuState = {
  regionMenuLoading: loadingStatus.idle,
  regionMenuData: [],
  currentStore: [],
  bestsellers: initialBestsellersState,
};
const FETCH_REGION_MENU: string = 'sideMenu/fetchRegionMenu';

// defaultMenu
export const getRegionMenu = createAsyncThunk(
  FETCH_REGION_MENU,
  async (storeId: string, thunkAPI) => {
    try {
      const regionId = storeId.slice(0, 4);
      const response = await fetchRegionMenu({ regionId });

      const data: IRegionMenu[] | never = [];
      const currentStore: IRegionMenu[] | never = [];
      // eslint-disable-next-line array-callback-return
      response.map((item: IRegionMenu | BestsellersMenu) => {
        const { Nodes } = item;
        if (Nodes.length > 0) {
          const isCurrentStore = Nodes.filter((filter) => filter.Id === storeId);
          const newNodeData = item.Nodes.map((elem) => {
            return {
              ...elem,
              Url: `${ecwebUrl({ path: `/store/${elem.Id}`, isPageUrl: true })}`,
              // gtm code
              gtmItemId: item.Id, // 小分類7碼
              gtmItemName: item.Name, // 小分類名稱
              gtmLocationBlock: 'side_menu',
              gtmLocationNode: `${item.Id}_${item.Name}`,
              gtmName: `${elem.Id}_${elem.Name}`,
            };
          });
          // 小分類
          const newData = {
            Id: item.Id,
            Name: item.Name,
            Nodes: newNodeData, // 小分類內的館清單
            Sort: item.Sort,
            Path: item.Path,
          };

          if (isCurrentStore.length > 0) {
            return currentStore.push(newData);
          }
          return data.push(newData);
        }
      });

      const bestsellersResponse = response.find((item) => item.Id === 'BestSellers');
      const bestsellersData = bestsellersResponse
        ? transformToBestsellerSideMenu(bestsellersResponse)
        : initialBestsellersState;

      return { regionMenuData: data, currentStore, bestsellersData };
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message.data);
    }
  }
);

const regionMenuSlice = createSlice({
  name: RegionMenuState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRegionMenu.pending, (state) => {
        state.regionMenuLoading = loadingStatus.pending;
        state.regionMenuData = [];
      })
      .addCase(getRegionMenu.fulfilled, (state, action) => {
        state.regionMenuLoading = loadingStatus.succeeded;
        state.regionMenuData = action.payload.regionMenuData;
        state.currentStore = action.payload.currentStore;
        state.bestsellers = action.payload.bestsellersData;
      })
      .addCase(getRegionMenu.rejected, (state) => {
        state.regionMenuLoading = loadingStatus.failed;
        state.regionMenuData = [];
      });
  },
});

type RegionMenuSliceReducer = {
  [RegionMenuState.Name]: ReturnType<typeof regionMenuSlice.reducer>;
};

export const regionMenuLoading = (state: RootState<RegionMenuSliceReducer>) =>
  state.regionMenu.regionMenuLoading ?? loadingStatus.idle;
export const regionMenuData = (state: RootState<RegionMenuSliceReducer>) =>
  state.regionMenu.regionMenuData;
export const regionMenu = (state: RootState<RegionMenuSliceReducer>) => state.regionMenu;
export default regionMenuSlice.reducer;
