import { HeaderMenuBannerApiResponse, HeaderMenuBannerData } from '@dfTypes/header/headerMenu';
import { isDev } from '@utils/config/env';
import { getFullImgDomain } from '@utils/getFullImgDomain';
import getBlockLink from '@utils/getBlockLink';

const toHeaderSignBanner = ({
  response,
  signId,
}: {
  response: HeaderMenuBannerApiResponse | any;
  signId: string;
}): HeaderMenuBannerData => {
  if (!response.menuBanner) {
    return {
      ScheduleId: undefined,
      SectionName: undefined,
      Name: undefined,
      Blocks: undefined,
      Duration: undefined,
    };
  }
  const { ScheduleId, SectionName, Name, Blocks, Duration } = response.menuBanner;
  const bannerBlock =
    Blocks.map((block, index) => {
      if (!block.Nodes) return [];
      const { BlockId, Sort, Nodes } = block;
      const { Id, Img, Link, ExtraData } = Nodes[0];
      return {
        blockId: BlockId,
        sort: Sort,
        bannerId: Id,
        link: getBlockLink(ExtraData.ElementType, Link?.Url),
        imageSrc: getFullImgDomain(Img.Src, isDev),
        imageAlt: Img.Text,
        GA: `b001_bn_a_01_0${Id}`,
        // gtm code
        gtmLocationId: 'mega_menu',
        gtmPromotionId: signId, // 路標ID
        gtmPromotionName: signId, // 路標ID
        gtmCreativeName: Img.Text, // 圖片名稱
        gtmCreativeSlot: `slot${index + 1}`, // 商品位置順序，從1開始排序
      };
    }) ?? [];
  return {
    ScheduleId,
    SectionName,
    Name,
    Blocks: bannerBlock,
    Duration,
  };
};
export { toHeaderSignBanner };
