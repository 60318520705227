import { ProdDescState, ProdDescAsyncThunkProps } from '@dfTypes/prod/prodDesc/View';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchProdDesc } from '@services/prod/prodDesc.api';
import { fetchProdButton } from '@services/prod/prodButton.api';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { initialDescData } from '@commonFeatures/desc/utils';
import { renderVersion } from '@utils/renderVersion';
import { safeJsonParser } from '@utils/safeJsonPaser';
import { getDescShow } from './transform/getDescShow.transformer';
import { toRelatedProdInfo } from './transform/toRelatedProdInfo.transformer';

export enum ProdDescNameState {
  Name = 'prodDesc',
}

const initialState: ProdDescState = {
  descLoading: loadingStatus.idle,
  desc: initialDescData,
  isDescShow: true,
  isShowNewSlogan: false,
  isRemarkNoData: false,
  addProdDesc: initialDescData, // 好康加購商品一般規格
  addProdIsDescShow: true, // 好康加購商品一般規格
  addProdIsRemarkNoData: false, // 好康加購商品一般規格
};

const FETCH_PROD_DESC: string = 'prod/fetchProdDesc';

export const getProdDesc = createAsyncThunk(
  FETCH_PROD_DESC,
  async (obj: ProdDescAsyncThunkProps) => {
    const { prodIds, sid, isSync, isAddProd } = obj;
    const prodListDesc = prodIds.map(
      async (prodId, index) =>
        // eslint-disable-next-line no-return-await
        await fetchProdDesc({
          version: renderVersion({ sid, dev: 'v2', other: 'v2' }),
          prodId,
          token: sid,
          callback: `jsonp_desc${index}`,
        })
    );
    const prodDesc = Promise.all(prodListDesc).then(async (prodDescContent) => {
      const descList = prodDescContent.map((res, index) => {
        const prodId: string = prodIds[index];
        const isDescShow: boolean = getDescShow(res[prodId]);
        const isShowNewSlogan: boolean = res[prodId].SloganInfo.length > 0;

        // 規格說明: AP字號
        const approveStr: string = safeJsonParser(JSON.stringify(res[prodId].Approve));
        const approveObj = approveStr ? safeJsonParser(approveStr) : '';
        // AP1: BSMI字號, AP2: NCC字號, AP3: 健康食品字號, AP4: 藥妝/彩妝字號, AP5: 廣告許可字號, AP6: 其他, AP7: 環保標章字號, AP8: 第二類環保標章字號, AP9: 碳足跡標籤字號, AP10: 碳足跡減量標籤字號, AP11: 節能標章字號
        const noApData = () => {
          if (approveObj === '') {
            return false;
          }
          return (
            ['AP1', 'AP2', 'AP3', 'AP4', 'AP5', 'AP6', 'AP7', 'AP8', 'AP9', 'AP10', 'AP11'].filter(
              (approve) => approveObj[approve]
            ).length === 0
          );
        };

        const isRemarkNoData = !res[prodId].Remark && noApData();
        return {
          desc: { ...res?.[prodId], Approve: approveObj },
          isDescShow,
          isShowNewSlogan,
          isRemarkNoData,
        };
      });
      const [descObj, mainProdDescObj] = descList; // 銷網商品(DescObj) 主商品(mainProdDescObj)
      const mainProdDesc = mainProdDescObj?.desc;
      const { Slogan, SloganInfo, RltProdInfo } = descObj.desc;
      let prodDescData: any;
      if (isSync) {
        prodDescData = {
          ...descObj,
          isDescShow: mainProdDescObj.isDescShow,
          isRemarkNoData: mainProdDescObj.isRemarkNoData,
          desc: {
            // 使用主商品一些內容設定("Stmt": "","Equip","Remark","Liability","Kword","Slogan","Author","Transman","Pubunit","Pubdate","Approve"
            ...mainProdDesc,
            Slogan, // 使用銷網內容
            SloganInfo, // 使用銷網內容
            RltProdInfo, // 使用銷網內容
          },
        };
      } else {
        prodDescData = descObj;
      }
      if (!isAddProd) {
        // 加購品不需要這一塊的處理，可以直接跳過
        const hasRltProdInfo: boolean = prodDescData.desc.RltProdInfo.length > 0;
        if (!hasRltProdInfo) {
          return prodDescData;
        }
        // 相關商品
        const relatedProdIdList = prodDescData.desc.RltProdInfo.map((item) => item.Id ?? '');
        const relatedProdPriceList = await fetchProdButton({
          prodId: relatedProdIdList?.join(','),
          version: renderVersion({ other: 'v2' }),
          prefix: 'related',
          fields: 'Seq,Id,Price,Qty,ButtonType,SaleStatus,isPrimeOnly,SpecialQty,Device',
        });

        const relatedProdInfo = toRelatedProdInfo({
          rltData: prodDescData.desc.RltProdInfo,
          priceData: relatedProdPriceList,
        });

        return {
          ...prodDescData,
          desc: { ...prodDescData.desc, RltProdInfo: relatedProdInfo },
          isAddProd,
        };
      }
      return {
        ...prodDescData,
        isAddProd,
      };
    });
    return prodDesc;
  }
);

const prodDescSlice = createSlice({
  name: ProdDescNameState.Name,
  initialState,
  reducers: {
    touchIsDescShow: (state) => {
      state.isDescShow = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getProdDesc.pending, (state) => {
        state.descLoading = loadingStatus.pending;
        state.desc = initialDescData;
        state.isDescShow = true;
        state.isRemarkNoData = false;
        state.addProdDesc = initialDescData;
        state.addProdIsDescShow = true;
      })
      .addCase(getProdDesc.fulfilled, (state, action) => {
        state.descLoading = loadingStatus.succeeded;
        if (action.payload.isAddProd) {
          state.addProdDesc = action.payload.desc;
          state.addProdIsDescShow = action.payload.isDescShow;
          state.addProdIsRemarkNoData = action.payload.isRemarkNoData;
        } else {
          state.desc = action.payload.desc;
          state.isDescShow = action.payload.isDescShow;
          state.isRemarkNoData = action.payload.isRemarkNoData;
          state.isShowNewSlogan = action.payload.isShowNewSlogan;
        }
      })
      .addCase(getProdDesc.rejected, (state) => {
        state.descLoading = loadingStatus.failed;
        state.desc = initialDescData;
        state.isDescShow = true;
        state.isRemarkNoData = false;
        state.isRemarkNoData = false;
        state.addProdDesc = initialDescData;
        state.addProdIsDescShow = true;
      });
  },
});

type ProdDescSliceReducer = {
  [ProdDescNameState.Name]: ReturnType<typeof prodDescSlice.reducer>;
};

export const { touchIsDescShow } = prodDescSlice.actions;
export const prodDesc = (state: RootState<ProdDescSliceReducer>) => state.prodDesc;
export default prodDescSlice.reducer;
