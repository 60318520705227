import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { fetchHeaderMenu } from '@services/common/headerMenu';
import { fetchHeaderMenuBanner } from '@services/common/headerMenuBanner';
import { loadingStatus } from '@utils/loadingStatus';
import { IHeaderHoverMenuState, IFetchHoverMenuOptions } from '@dfTypes/header/headerMenu';
import { getTidyMenuData } from '@commonFeatures/header/transform/getTidyMenuData';
import { addCallApiMenuList } from '@commonFeatures/common/commonHeaderSlice';

export enum HeaderHoverMenuState {
  Name = 'headerHoverMenu',
}

const initialState: IHeaderHoverMenuState = {
  hoverLoading: loadingStatus.idle,
  hoverMenu: [],
  hoverMenuBanner: [],
  tempMenuBannerList: {},
};
const FETCH_HOVER_MENU: string = 'fetchHoverMenu';

export const fetchHoverMenu = createAsyncThunk(
  FETCH_HOVER_MENU,
  async (options: IFetchHoverMenuOptions, thunkAPI) => {
    const { signId, tempBanner, op, ts } = options;

    try {
      return await Promise.all([
        fetchHeaderMenu({
          signId: signId === 'books' ? 'book' : signId,
          callback: `cb_ecshopCategoryRegion_${signId}`,
        }),
        fetchHeaderMenuBanner({
          signId: signId === 'books' ? 'book' : signId,
          op,
          ts,
        }),
      ]).then((res) => {
        const sortMenu = res[0]
          ?.filter((item) => item.Nodes.length !== 0)
          .sort((a, b) => b.Sort - a.Sort); // 相關分類->品牌分類
        const tidyMenuData = getTidyMenuData(sortMenu);
        // new tempMemuList
        const refactorSignName = signId === 'book' ? 'books' : signId;
        const tempMenuList = { [refactorSignName]: tidyMenuData };
        const banner = res[1] ? res[1].Blocks : [];
        // new tempMenuBannerList
        const tempMenuBannerList = { ...tempBanner, [refactorSignName]: banner };
        thunkAPI.dispatch(addCallApiMenuList(tempMenuList));
        return { response: tidyMenuData, banner, tempMenuBannerList };
      });
    } catch (err: any) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
  }
);

const headerHoverMenuSlice = createSlice({
  name: HeaderHoverMenuState.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHoverMenu.pending, (state) => {
        state.hoverLoading = loadingStatus.pending;
      })
      .addCase(fetchHoverMenu.fulfilled, (state, action) => {
        state.hoverLoading = loadingStatus.succeeded;
        state.hoverMenu = action.payload.response;
        state.hoverMenuBanner = action.payload.banner;
        state.tempMenuBannerList = action.payload.tempMenuBannerList;
      })
      .addCase(fetchHoverMenu.rejected, (state) => {
        state.hoverLoading = loadingStatus.failed;
        state.hoverMenu = [];
        state.hoverMenuBanner = [];
      });
  },
});

type HeaderHoverMenuSliceReducer = {
  [HeaderHoverMenuState.Name]: ReturnType<typeof headerHoverMenuSlice.reducer>;
};

export const hoverLoading = (state: RootState<HeaderHoverMenuSliceReducer>) =>
  state?.headerHoverMenu.hoverLoading ?? loadingStatus.idle;
export const tempMenuBannerList = (state: RootState<HeaderHoverMenuSliceReducer>) =>
  state?.headerHoverMenu?.tempMenuBannerList ?? [];
export default headerHoverMenuSlice.reducer;
