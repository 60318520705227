// response
export type SearchAutosuggestResponse = AutosuggestItem[];

type AutosuggestItem = {
  name: string; // Name: string;
  rurl: string; // Rurl: string;
  src: string; // Src: string;
  BU: string; // business name
};

// view
export type SearchAutosuggestData = SuggestionSectionItem[];

export enum SuggestionType {
  Brand = 'brand',
  General = 'general',
  Mcr = 'mcr', // 比比昂日本購物(bibian)
}

export type SuggestionItem = {
  name: string;
  businessName: string;
  logoSrc: string;
  rurl: string;
  type: SuggestionType;
};

export type SuggestionSectionItem = {
  title: SuggestionType;
  suggestions: SuggestionItem[];
} | null;
