// https://ecapi-cdn.pchome.com.tw/cdn/hotkeyword/v1/hotkeyword&_callback=jsonpcb_hotkeyword&2796814
import '@utils/http-interceptor';
import { THotkeywordResponse } from '@dfTypes/store/hotkeyword/Response';
import Fetch from '../utils/jsonp';
import { ecapiUrl } from '../utils/domain-route';

function fetchHotkeyword(): Promise<THotkeywordResponse> {
  const url = ecapiUrl({ path: '/cdn/hotkeyword/v2/hotkeyword&_callback=jsonp_fetchHotkeyword&1' });

  return Fetch.jsonp({
    url,
    callback: 'jsonp_fetchHotkeyword',
  });
}
export default fetchHotkeyword;
