import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { loadingStatus } from '@utils/loadingStatus';
import { RootState } from 'src/store';
import { fetchLogout } from '@app/services/common/logout.api';
import { getLoginInformation } from './loginMemberInformationSlice';
import { getPersonalItemCartCount } from './personalItemCartSlice';

export enum LogoutStateName {
  Name = 'logout',
}
type LogoutState = {
  logoutInformationLoading: string;
};
const initialState: LogoutState = {
  logoutInformationLoading: loadingStatus.idle,
};
type getLogoutProps = {
  memberId: string;
  memberX: string;
};
export const getLogout = createAsyncThunk<LogoutState | any, getLogoutProps, { state }>(
  'get/loginInformation',
  async ({ memberId, memberX }, thunkAPI) => {
    await fetchLogout({ memberId });
    Promise.all([
      thunkAPI.dispatch(getLoginInformation(memberX)),
      thunkAPI.dispatch(getPersonalItemCartCount()),
    ]).then(() => {
      window.location.reload();
    });
  }
);

const logoutSlice = createSlice({
  name: LogoutStateName.Name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLogout.pending, (state) => {
        state.logoutInformationLoading = loadingStatus.pending;
      })
      .addCase(getLogout.fulfilled, (state, action) => {
        state.logoutInformationLoading = loadingStatus.succeeded;
      })
      .addCase(getLogout.rejected, (state) => {
        state.logoutInformationLoading = loadingStatus.failed;
      });
  },
});

type LogoutSliceReducer = {
  [LogoutStateName.Name]: ReturnType<typeof logoutSlice.reducer>;
};

export const logout = (state: RootState<LogoutSliceReducer>) => state?.logout;
export default logoutSlice.reducer;
